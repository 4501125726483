<template>
  <div class="cases-item header">
    <div class="container">
      <el-carousel
        :interval="3000"
        arrow="never"
        height="600px"
      >
        <el-carousel-item
          class="item"
          v-for="item in data"
          :key="item.developer"
        >
          <div class="left">
            <h2 class="logo"><img loading="lazy" :src="item.logo" :alt="item.developer" /></h2>
            <h3 class="title">{{ item.title }}</h3>
            <p class="text">{{ item.text }}</p>
            <div class="statistics">
              <div class="statistics-number">
                <h4>{{ item.count }}</h4>
                <p>总访问量</p>
              </div>
              <div class="statistics-number">
                <h4>{{ item.number }}</h4>
                <p>访问人数</p>
              </div>
              <div class="statistics-number">
                <h4>{{ item.share }}</h4>
                <p>小程序分享次数</p>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="img-box">
              <img loading="lazy" :src="item.cases" :alt="item.developer" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "CasesKa",
  data() {
    return {
      data: [
        {
          developer: "招商蛇口",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/zhaoshan-logo.png",
          title: "全面升级线上营销平台，实现全周期数字化营销闭环",
          text: "我们谈的数字化转型本质上不是技术转型，而是通过数字化技术的应用和创新支撑业务发展。",
          count: "8200万+",
          number: "255万+",
          share: "140万+",
          // cases: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/zhaoshan-cases.png',
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/zhaoshan-cases.jpeg",
        },
        {
          developer: "越秀地产",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/yuexiu-logo.png",
          title: "“越秀房宝”助力越秀数字化营销升级，迈入地产营销新阶段",
          text: "如果大家还是停留在过去这种传统的营销思维上面，就是坐着马车和开着汽车的人赛跑。这时投放的营销费用越多，效果就越差。",
          count: "4000万+",
          number: "360万+",
          share: "320万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/yuexiu-cases.jpeg",
        },
        {
          developer: "中海地产",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/zhonghai-logo.png",
          title: "全周期客户运营平台项目，实现客户与中海品牌黏性沟通",
          text: "",
          count: "1900万+",
          number: "125万+",
          share: "45万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/zhonghai-cases.jpeg",
        },
        {
          developer: "融创",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/rongchuan-logo.png",
          title: "练就超级IP！思为助力融创实现自有内容的构建与培育",
          text: "构建官方私域流量平台将是房企实现自有内容构建与培育的最佳路径。",
          count: "1800万+",
          number: "150万+",
          share: "500万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/rongchuan-cases.jpeg",
        },
        {
          developer: "金科",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/jinke-logo.png",
          title: "内外双轮驱动，数字化注入发展新功能",
          text: "产品+品效+数字化”，这就是未来十年房地产营销的基本盘。",
          count: "5500万+",
          number: "105万+",
          share: "45万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/jinke-cases.jpeg",
        },
     
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.cases-item {
  width: 100%;
  height: auto;
  // background: #EDF1F4;
  background: #ffffff;
  box-sizing: border-box;
  text-align: center;
  
  overflow: hidden;
  .container {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0 0 0;
    position: relative;
  
    .item {
      display: flex;
      .left {
        width: 50%;
        padding-right: 100px;
        text-align: left;
        .logo {
          width: 120px;
          height: 32px;
          display: flex;
          align-items: center;
          
          img {
            max-width: 100%;
          }
        }
        .title {
          width:408px;
          margin-top: 50px;
          font-size: 28px;
          font-weight: 600;
          color: #2d2e30;
        }
        .text {
          margin-top: 30px;
          font-size: 14px;
          font-weight: 400;
           width:408px;
          color: #5a5c60;
        }
        .statistics {
          display: flex;
          text-align: center;
          margin-top: 60px;
          &-number {
            margin-right: 70px;
            font-size: 14px;
            font-weight: 400;
            color: #5a5c60;
            h4 {
              width:110px;
              font-size: 24px;
              font-weight: 500;
              color: #2d2e30;
            }
          }
        }
      }
      .right {
        width: 50%;
        display: flex;
        justify-content: end;
        .img-box {
          width: 430px;
          height: 640px;
          
          border: 10px solid #fff;
          border-radius: 22px 22px 0 0;
           box-shadow: 0px 4px 12px 0px rgba(155,165,192,0.25);
        }
        img {
          width: 408px;
          border-radius: 12px 12px 0 0;
        }
      }
    }
    ::v-deep .el-carousel__indicators--horizontal {
      bottom: 40px;
      width: 1180px;
      padding-left: 20px;
      text-align: left;
    }
    ::v-deep .el-carousel__indicators--outside {
      position: absolute;
    }
    ::v-deep .el-carousel__button {
      display: block;
      width: 36px;
      margin: 0 2px;
      height: 3px;
      background: #C1CCDD;
      position: relative;
    }
    ::v-deep .is-active .el-carousel__button {
      height: 3px;
      background: #3777f6;
    }
    ::v-deep .el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 50px;
      width: 50px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 2px 24px 0px rgba(8, 33,83, 0.1);
      color: #878A90;
      position: absolute;
      top: 40%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      font-size: 24px;
    }
  
  }
}

// 移动端布局
@import "@/views/cases/min-cases";
</style>

