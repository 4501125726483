<template>
  <div class="history-page">
    <div class="history-page-max">
      <el-carousel indicator-position="outside">
        <el-carousel-item
          v-for="(history, parentIndex) in allHistoryData"
          :key="parentIndex"
        >
          <div class="step-box">
            <div class="step">
              <div
                class="step-item"
                v-for="(item, index) in history.top"
                :key="item.year"
              >
                <div class="step-item-dot active">
                  <div
                    class="step-item-event"
                    ref="stepBlockRef"
                    @mouseleave="onWrapperOut('top', index,parentIndex)"
                  >
                    <div
                      class="step-item-wrapper"
                      @mouseover="onWrapperOver('top', index,parentIndex)"
                    >
                      <div class="title">{{ item.year }} <span class="unit">年</span></div>
                      <div class="content" v-for="(h, i) in item.list" :key="i">
                        <span class="title-month">{{ h.title }}</span>
                        <span class="text">{{ h.text }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="step-item-dot"></div>
                <div class="step-item-dot"></div>
                <div class="step-item-dot"></div>
                <div class="step-item-dot"></div>
                <div class="step-item-dot active step-item-bottom-dot">
                  <!-- bottom 是否存在 -->
                  <div
                    class="step-item-event"
                    v-if="history.bottom[index]"
                    ref="stepBlockBottomRef"
                    @mouseleave="onWrapperOut('bottom', index,parentIndex)"
                  >
                    <div
                      class="step-item-wrapper"
                      @mouseover="onWrapperOver('bottom', index,parentIndex)"
                    >
                    <div class="title">{{ history.bottom[index].year }} <span class="unit">年</span></div>
                    <div
                      class="content"
                      v-for="(h, i) in history.bottom[index].list"
                      :key="i"
                    >
                      <span class="title-month">{{ h.title }}</span>
                      <span class="text">{{ h.text }}</span>
                    </div>
                  </div>
               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="history-page-min">
      <div class="history-wrapper">
        <div
          class="history-wrapper-item"
          v-for="item in foldData"
          :key="item.year"
        >
          <div class="item-left">
            <div class="dot"></div>
          </div>
          <div class="item-right">
            <div class="item-right-title">
              {{ item.year }} <span class="unit">年</span>
            </div>
            <div class="content" v-for="(h, i) in item.list" :key="i">
              <span class="title-month">{{ h.title }}</span>
              <span class="text">{{ h.text }}</span>
            </div>
          </div>
        </div>
        <div class="history-footer" @click="onFold">
          {{ foldText }}
          <i
            :class="
              foldText === '展开全部'
                ? 'el-icon-arrow-down'
                : 'el-icon-arrow-up'
            "
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chunk } from 'lodash';
export default {
  name: 'AboutHistory',

  data() {
    return {
      active: 0,
      visible: true,
      historyData: [
        {
          year: '2023',

          list: [
            {
              year: '2023',
              title: '3月',
              text: '获批深圳市博士后创新实践基地',
            },
            {
              year: '2023',
              title: '3月',
              text: '入选2022年深圳市“专精特新”中小企业',
            },
            {
              year: '2023',
              title: '2月',
              text: '与行稳智能达成“行业独家战略合作”协议',
            },
          ],
        },
        {
          year: '2022',

          list: [
            {
              year: '2022',
              title: '9月',
              text: '与中科院深圳先进院共创元宇宙空间计算联合实验室',
            },
            {
              year: '2022',
              title: '3月',
              text: '完成由招商局创投、火山石投资领投的C轮融资',
            },
          ],
        },
        {
          year: '2021',

          list: [
            {
              year: '2021',
              title: '12月',
              text: '与循环智能达成“行业独家战略合作”协议',
            },
            {
              year: '2021',
              title: '8月',
              text: '思为科技十周年，全面升级品牌使命、愿景、价值观',
            },
            {
              year: '2021',
              title: '4月',
              text: '完成由火山石投资领投，同创伟业跟投的C1轮融资',
            },
            {
              year: '2021',
              title: '3月',
              text: '全面实现地产营销“线上线下一体化”闭环',
            },
            {
              year: '2021',
              title: '1月',
              text: '思为云创营销场景覆盖率超95%，服务用户破1亿',
            },
          ],
        },
        {
          year: '2020',

          list: [
            {
              year: '2020',
              title: '11月',
              text: '与8家TOP20开发商建立区域性、集团性合作',
            },
            {
              year: '2020',
              title: '8月',
              text: '思为营销云小程序上线超300个',
            },
            {
              year: '2020',
              title: '7月',
              text: '与保利旗下锐云科技成立创新联合实验室',
            },
            {
              year: '2020',
              title: '6月',
              text: '推出企业微信生态内首个地产数字化营销解决方案',
            },
            {
              year: '2020',
              title: '2月',
              text: '思为营销云小程序上线超100个，在新冠疫情期间实现规模化行业赋能',
            },
          ],
        },
        {
          year: '2019',

          list: [
            {
              year: '2019',
              title: '10月',
              text: '联合碧桂园共建“凤凰云创新营销联合实验室”',
            },
            {
              year: '2019',
              title: '8月',
              text: '入选首期腾讯SaaS加速器，获腾讯全生态扶持',
            },
            {
              year: '2019',
              title: '6月',
              text: '完成B轮融资，由朗玛峰领投，同创伟业、微禾跟投，员工参与认股',
            },
          ],
        },
        {
          year: '2018',

          list: [
            {
              year: '2018',
              title: '9月',
              text: '拿下纽约、伦敦、澳洲、新加坡、马来西亚等多处地标项目，海外扩张步伐加快',
            },
            {
              year: '2018',
              title: '1月',
              text: '提出“流态营销”理论，为房地产数字化营销2.0时代提供理论支撑',
            },
          ],
        },
        {
          year: '2017',

          list: [
            {
              year: '2017',
              title: '12月',
              text: '完成由同创伟业领投的A+轮融资',
            },
          ],
        },
        {
          year: '2016',

          list: [
            {
              title: '10月',
              year: '2016',
              text: '完成由朗玛峰领投的A轮融资',
            },
          ],
        },
        {
          year: '2015',

          list: [
            {
              year: '2015',
              title: '8月',
              text: '完成天使轮融资，先后在深圳、海口、三亚、南宁等地设置分公司及分支机构',
            },
          ],
        },
        {
          year: '2013',

          list: [
            {
              year: '2013',
              title: '7月',
              text: '思为革命性多点触控平台亮相美国InfoComm展示，获得高度好评',
            },
          ],
        },
        {
          year: '2012',

          list: [
            {
              year: '2012',
              title: '5月',
              text: '思为移动触控售楼软件获软件著作登记书，思为科技成为广东省认证的双软企业',
            },
          ],
        },
        {
          year: '2011',

          list: [
            {
              year: '2011',
              title: '8月',
              text: '思为科技成立，开始研发Idea-Make售楼系统',
            },
          ],
        },
      ],
      foldText: '展开全部',
      mobileLimit: 3,
      stepBlockRef: null,
      stepBlockBottomRef: null,

      pageSize: 8
    };
  },
  computed: {
    // 每页有8个，上下各4个
    allHistoryData() {
      const arr = chunk(this.historyData, this.pageSize);
      return arr.map((item) => {
        const topArr = [];
        const bottomArr = [];
        item.map((el, i) => {
          if (i % 2 === 0) {
            topArr.push(el);
          } else {
            bottomArr.push(el);
          }
        });
        return {
          top: topArr,
          bottom: bottomArr,
        };
      });
    },
    foldData() {
      if (this.foldText === '展开全部') {
        return this.historyData.slice(0, 3);
      }
      return this.historyData;
    },
  },
  methods: {
    onFold() {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `移动端关于我们发展历程展开收起`,
      ]);
      this.foldText = this.foldText === '展开全部' ? '收起' : '展开全部';
    },

    // 解决hover反复抖动的问题
    onWrapperOut(type, index,parentIndex) {
      const refIndex = parentIndex * this.pageSize /2 + index
      if (type === 'top') {
        this.$refs.stepBlockRef[refIndex].style.transform = 'translateY(0)';
      } else {
        this.$refs.stepBlockBottomRef[refIndex].style.transform = 'translateY(0)';
      }
    },
    onWrapperOver(type, index,parentIndex) {
   
      const refIndex = parentIndex * this.pageSize /2 + index
      if (type === 'top') {
        this.$refs.stepBlockRef[refIndex].style.transform = 'translateY(-10px)';
      } else {
        this.$refs.stepBlockBottomRef[refIndex].style.transform =
          'translateY(10px)';
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.history-page {
  margin-top: 10px;
  width: 100%;
  // height: 724px;
  position: relative;
  &-img {
    // width: 100%;
    position: absolute;
    // top: -175px;
    // height: 100%;
    width: 1200px;
    // height: calc(648px - 175px);
  }
  .history-page-min {
    display: none;
  }
  .step {
    position: relative;
    display: flex;
    // justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 14px 14px 14px 30px;
    &-item {
      font-size: 20px;
      color: #878a90;
      cursor: pointer;
      display: flex;
      z-index: 2;
      &-dot {
        display: block;
        width: 12px;
        height: 12px;
        background: #a6c4ff;
        border-radius: 50%;
        margin-left: 26px;
        &:first-child {
          margin-left: 0;
        }
      }
      &-bottom-dot {
        margin-right: 60px;
      }
    }
    &-box {
      position: absolute;
      top: 50%;
      margin-left: 15px;
      transform: translateY(-50%);
    }
  }
  .step::after {
    content: '';
    height: 1px;
    width: calc(100% - 84px);
    background: #5597fd;
    position: absolute;
    left: 30px;
    top: 20px;
    z-index: 1;
  }
  .active {
    font-size: 22px;
    font-weight: 500;
    color: #2d2e30;
    &.step-item-dot {
      position: relative;
      width: 16px;
      height: 16px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(55, 119, 246, 0.7);
      border: 1px solid #3777f6;
      top: -2px;
    }

    &.step-item-dot::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: linear-gradient(180deg, #3777f6 0%, #5597fd 100%);
      border-radius: 50%;
      position: absolute;
      margin: auto;
      top: 0px;
      left: 0;
      bottom: 0;
      right: 0;
    }
    .step-item-wrapper {
      // margin: 12px;
      // margin-bottom: 24px;
      height: 100%;
      box-sizing: border-box;
      .unit{
          font-size: 16px;
          font-family: DINAlternate-Bold, DINAlternate;
          margin-left: -4px;
        }
    }
    .step-item-event {
      position: absolute;
      width: 250px;
      height: 320px;
      background: #fff;
      top: -336px;
      left: -20px;
      padding: 12px;
      box-shadow: 8px 8px 20px 0px rgba(55, 99, 170, 0.1);
      // box-shadow: 8px 8px 20px 0px red;
      border-radius: 4px;
      .title {
        font-weight: bold;
        color: #2d2e30;
        font-size: 26px;
        font-family: DINAlternate-Bold, DINAlternate;
    
      }
      .content {
        font-size: 14px;
        font-weight: 400;
        color: #5a5c60;
        margin-top: 8px;
        &:first-child {
          margin-top: 0px;
        }
      }
      .title-month {
        font-weight: 500;
        color: #2d2e30;
        margin-right: 8px;
      }
      &::after {
        content: ' ';
        position: absolute;
        left: 12px;
        width: 0;
        height: 0;
        border-top: 20px solid #fff;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        bottom: -10px;
      }
      // &:hover {
      //   transform: translateY(-10px);
      // }
    }
    &.step-item-bottom-dot {
      // top: 350px;
      .step-item-event {
        top: 30px;
        left: -215px;
        &::after {
          border-bottom: 20px solid #fff;
          border-right: 15px solid transparent;
          border-left: 15px solid transparent;
          border-top: 0px solid transparent;
          top: -10px;

          bottom: 0px;

          left: 207px;
        }
        // &:hover {
        //   transform: translateY(10px);
        // }
      }
    }
  }
  ::v-deep .el-carousel {
    // width: 400px;
    .el-carousel__container {
      // margin-top: 40px;
      height: 780px;
      .el-carousel__arrow {
        display: none;
      }
    }
    .el-carousel__indicator {
      padding: 0;
      width: 20px;
      margin-right: 40px;
      margin-top: 10px;
      .el-carousel__button {
        background: #3777f6;
        width: 52px;
        margin-right: 24px;
      }
    }
  }
}

// 移动端布局
@import '@/views/about/min-about';
</style>
