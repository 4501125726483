<template>
  <div class="partner">
    <div class="partner-header">
      <div class="partner-title-word">
        <div class="partner-title-big">思为科技渠道代理诚邀加盟</div>
        <div class="partner-title-small">抢占营销数字先机
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          共享万亿级地产红利</div>
      </div>
    </div>
    <div class="home-con">
      <div class="home-con-item">
        <div class="first-word">
          <div class="first-word-big">
            思为科技合作伙伴计划正式启动
          </div>
          <div class="first-word-small">
            <div class="first-word-small-item">
              生态合作模式
            </div>
            
            <div class="first-word-small-content">
              <img loading="lazy" src="https://cdn.ideamake.cn/sw-pc/images/cooperation-mode-one.png" alt="">
               <span>城市代理合作</span>
               <img loading="lazy" src="https://cdn.ideamake.cn/sw-pc/images/cooperation-mode-two.png" alt="">
               <span>异业生态联盟</span>
            </div>
            <div class="first-word-small-item">
              生态扶持体系
            </div>
            <div class="first-word-small-content" style="display: flex;">
              
              <el-carousel ref="cardShow" indicator-position="outside" height=100 :interval="3000" arrow="always">
                <el-carousel-item >
                  <p>完善机制：颁发思为代理授权书，防撞单跟进报备机制</p>
                  <p>物料支持：宣传设计、产品手册、优秀案例等物料支持</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p>专业培训：一对一专业指导，产品功能及销售技巧培训</p>
                  <p>市场协助：共享优质商机线索资源，快速推进市场拓展</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p>区域保护：申请区域独家代理合作，独享区域市场份额</p>
                  <p>售后赋能：专业运营团队落地支持，24h技术服务在线</p>
                </el-carousel-item>
              </el-carousel>
              <div class="rightArrow" @click="arrowClick('right')" >
                <i class="el-icon-arrow-right"></i>
              </div>

            </div>
            <div class="first-word-small-item">
              生态合作流程
            </div>
            <div class="first-word-small-content line-box">
              <div class="con-line-box">
                <div class="content-run">
                  <div class="content-icon"></div>
                </div>
                <ul lin inline-block class="linebox">
                  <li class="relative"><div>加盟申请</div></li>
                  <li class="relative"><div>资质审核</div></li>
                  <li class="relative"><div>合作洽谈</div></li>
                  <li class="relative"><div>合同签约</div></li>
                  <li class="relative"><div>培训辅导</div></li>
                  <li class="relative"><div>开展业务</div></li>
                </ul>
              </div>
            </div>
            <div class="first-word-big-button" @click="onConsult">成为合作伙伴</div>
          </div>
        </div>
        <div class="partner-right-img">
          <img loading="lazy" class="first-img" src="https://cdn.ideamake.cn/sw-pc/images/cooperative-partner-big.png" alt="">
        </div>
      </div>
    </div>
    <CasesItem title='顾客的认可  是最好的背书' :showNumber="showNumber"></CasesItem>
    <marketing-upgrade></marketing-upgrade>
  </div>
</template>

<script>
import marketingUpgrade from '@/components/MarketingUpgrade.vue'
import CasesItem from "@/components/CasesItem.vue"
import swTracker from '@/mixins/tracker.js'
import { swTrackerClickReport } from '@/tracker.js'

export default {
  name: "partnerView",
  mixins: [swTracker],
  components: {
    marketingUpgrade,
    CasesItem,
  },
  data () {
    return {}
  },
  computed: {
    showNumber () {
      return this.browserWigth > 980 ? 3 : 4
    },
  },
  created(){
    window.addEventListener('resize', this.getBrowserWidth)
    this.getBrowserWidth()
  },
  destroyed(){
    window.removeEventListener('resize', this.getBrowserWidth)
  },
  methods: {
    arrowClick(val) {
      if(val === 'right') {
        this.$refs.cardShow.next()
      }
    },
    onConsult (){
      swTrackerClickReport('sw_pc_free_trial', '成为合作伙伴', '合作伙伴')
      this.$store.commit('doStayConsultation')
    },
    // 获取浏览器宽度
    getBrowserWidth(){
      this.browserWigth = window.innerWidth
    }
  },
}
</script>
<style lang="scss" scoped>
 .partner{
    background-color: #FFFFFF;
    .partner-header{
      width: 100%;
      height: 480px;
      background: url('https://cdn.ideamake.cn/sw-pc/images/cooperative-partner-header.png');
      background-size: cover;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .partner-title-big{
        font-size: 42px;
        font-weight: 600;
        color: #FFFFFF;
      }
      .partner-title-small{
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 32px;
        &::before{
          content: '';
          display: inline-block;
          width: 42px;
          height: 1px;
          background: #FFFFFF;
          margin-right: 10px;
          vertical-align: 5px;
        }
        &::after{
          content: '';
          display: inline-block;
          width: 42px;
          height: 1px;
          background: #FFFFFF;
          margin-left: 10px;
          vertical-align: 5px;
        }
    
      }
    }
    .home-con{
        margin: 0 auto;
        width: 1200px;
        padding: 40px 0 100px;
        height: 770px;
        .home-con-item{
          display: flex; 
          justify-content: space-between;
          padding-top: 42px;
          .first-word {
              padding: 0 0 0 15px;
              .first-word-big{
                  color: #2D2E30;
                  font-size: 28px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  margin-bottom: 8px;
              }
              .first-word-small{
                  overflow: hidden;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #2D2E30;
                  font-size: 14px;
                  width: 530px;
                  .first-word-small-item{
                    font-size: 18px;
                    font-weight: bold;
                    margin: 50px 0 16px 0px;
                  }
                  .first-word-small-content{
                    width: 100%;
                    position: relative;
                    .content-run{
                      overflow: hidden;
                      width: 530px;
                      animation: run 3s linear infinite;
                      position: absolute;
                      .content-icon{
                        width: 25px;
                        height: 5px;
                        border-radius: 30%;
                        background-color: #3777F6;
                      }
                    }
                    @keyframes run {
                      0% {
                        transform: translateX(0);
                      }
                      100% {
                        transform: translateX(530px);
                      }
                    }
                    .linebox{
                      display: flex;
                      background: #e1e1e1;
                      height: 4px;
                      border-radius: 4px;
                      text-align: center;
                      .relative{               
                          height: 8px;
                          width: 8px;
                          border-radius: 8px;
                          background: #3777F6;
                          margin: -2px 40px auto;
                          float: left;
                          & div{
                              margin-top: 18px;
                              width: 90px;
                              font-size: 14px;
                              margin-left: -40px;
          
                          }
                        }
                    }
                    .rightArrow{
                      height: 50px;
                      width: 50px;
                      color: #3777F6;
                      border: 2px solid #3777F6;
                      border-radius: 50%;
                      line-height: 50px;
                      font-size: 30px;
                      cursor: pointer !important;
                      margin-top: 42px;
                      text-align: center;
                     & i{
                      font-weight: 600 !important;
                     }
                     &:hover{
                      color: #FFF;
                      background: #3777F6;
                     }
                    }
                
                    ::v-deep .el-carousel{
                      width: 400px;
                      .el-carousel__container{
                        margin-top: 40px;
                        height: 59px;
                        .el-carousel__item{
                          width: 350px;
                          line-height: 2;
                        }
                        .el-carousel__arrow{
                          display: none;
                        }
                      }
                      .el-carousel__indicator{
                        padding: 0;
                        width: 20px;
                        margin-right: 40px;
                        .el-carousel__button{
                            display: inline-block;
                            opacity: 1;
                            background: #fff;
                            border: 3px solid #3777F6;
                            height: 12px;
                            width: 12px;
                            margin: 0 20px 0 10px;
                            position: relative; 
                            border-radius: 50%;
                          &::after{
                            content: '';
                            display: block;
                            position: absolute;
                            left: -10px;
                            right: -10px;
                            bottom: -10px;
                            top: -10px;
                            background: rgba(21,83,245,.5);
                            border-radius: 50%;
                            opacity: 0;
                          }
                          // &::before{
                          //   content: '';
                          //   display: block;
                          //   position: absolute;
                          //   left: -2px;
                          //   top: -2px;
                          //   background: red;
                          //   border-radius: 50%;
                          //   opacity: 0;
                          //   width: 24px;
                          //   height:24px;
                          //   z-index: 1;
                          // }
                        }
                      }
                      .el-carousel__indicators--outside {
                          top: 0px;
                          left: 0px;
                      }
                      .el-carousel__indicators--outside button{
                        opacity: 1;
                      }
                      .el-carousel__indicators{
                        .is-active{
                          
                          .el-carousel__button{
                            background: #3777F6;
                            border-color: transparent;
                          &::after{
                            opacity: 1;
                          }
                        }
                          // border-color: blue;
                          // background: blue;
                          // border-radius: 50%;
                          // .el-carousel__button{
                          //   &::after{
                          //     opacity: 0;
                          //   }
                          //   &::before{
                          //     opacity: 1;
                          //   }
                          // }
                        }
                      }
                     
                      .el-carousel__indicators--outside{
                        position: absolute;
                        bottom: 0;
                        top: 5px;
                      }
                    }
                  }
                  .first-word-small-content img{
                    height: 50px;
                    width: 50px;
                    margin-right: 9px;
                  }
                  .first-word-small-content span{
                    margin-right: 35px;
                    font-size: 15px;
                    color: #555;
                  }
                  .first-word-big-button{
                      width: 148px;
                      height: 48px;
                      background: #3777F6;
                      border-radius: 2px;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #FFFFFF;
                      line-height: 48px;
                      cursor: pointer;
                      text-align: center;
                      margin-top: 100px;
                      &:hover{
                        background: #5F92F8;
                      }
                  }
              }
          }
          .partner-right-img{
            width: 614px;
          }
          .first-img{
              width: 614px;
              height: 516px;
              margin-top: 68px;
          }
      }
    }
    
  }


// 移动端布局
@import "@/views/partner/min-partner";
</style>
