<template>
  <div class="newMenu">
    <div class="home-con">
      <span class="title">覆盖客户购房生命旅程的全链路运营</span>
      <div class="content">思为营销云目前已为600+房企提供数字化赋能服务，拉通获客-运营-转化-沉淀全链路</div>
      <el-image 
        class="content-img" 
        src="https://cdn.ideamake.cn/sw-pc/images/web3.0/cultural-wall.png" 
        :preview-src-list="srcList">
      </el-image>
    </div>
  </div>
</template>
<script>
export default {
  name: 'new-menu',
  data () {
    return {
      srcList: [
        "https://cdn.ideamake.cn/sw-pc/images/web3.0/cultural-wall.png",
      ]
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.newMenu{
  .home-con{
    margin: 0 auto;
    width: 1200px;
    padding: 189px 0 100px;
    height: 718px;
    text-align: center;
    .title{
      font-size: 32px;
      font-weight: 500;
      color: #2D2E30;
      line-height: 40px;
    }
    .content{
      padding-top: 16px;
      padding-bottom: 45px;
      font-size: 16px;
      font-weight: 400;
      color: #5A5C60;
      line-height: 24px;
     
    }
    .content-img{
      width: 100%;
      height: 411px;
    }
  }
}
// 移动端布局
@import "../min-home";
</style>
  