<template>
  <div class="card">
    <div
      class="card-box"
      v-for="(item, index) in dataSouce"
      :key="'card' + index"
      @click="goReservation(item.code,item.type)"
    > 
      <div class="card-box-banner" :style="{ backgroundImage: `url(${item.banner})`}">
        <div class="header">
          <img loading="lazy" class="icon" :src="item.img" :alt="item.title">
          <span>{{ item.title }}</span>
        </div>
        <ul class="body">
          <li v-for="(text, i) in item.list" :key="'list' + i">
            {{ text }}
          </li>
        </ul>
        <div class="text">
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SolutionCard",
  props:{
    dataSouce:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
    goReservation( code,type ) {
      switch (type) {
        case 2:
          window._hmt.push(['_trackEvent', 'button','click', '首页行业报告立即下载按钮']);
          break;
        case 3:
          window._hmt.push(['_trackEvent', 'button','click', '首页前沿打法立即获取按钮']);
          break;
        case 4:
          window._hmt.push(['_trackEvent', 'button','click', '首页优质案例立即了解按钮']);
          break;
      }
      if( !code ) {
        this.$router.push({ name: 'reservation',params:{type} })
      } else {
        window.open('https://applmkip5ay1116.pc.xiaoe-tech.com/page/2088805?navIndex=0', '_blank')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &-box {
    text-align: left;
    position: relative;
      .card-box-banner{
        width: 384px;
        height: 156px;
        background-size: cover;
        box-shadow: 0px 4px 12px 0px rgba(155,165,192,0.25), inset 0px 1px 3px 0px rgba(0,0,0,0.03);
        background-size: cover;
        border-radius: 6px;
        border: 1px solid #FFFFFF;
        &:hover{
          .header {
            color: #3777F6;
          
          }
          
          margin-top: -10px;
          .text::after {
            animation: pulse .8s linear infinite alternate; 
          }
          
        }
      }
      
     .header {
       height: 50px;
       font-size: 20px;
       font-weight: 500;
       color: #2D2E30;
       padding:26px ;
       .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 6px;
        vertical-align: -5px;
      }
     }
    .body {
      height: 35px;
      margin-top: 22px;
      li {
        position: relative;
        margin: 10px 0 0 28px;
         color: #5A5C60;
      }
    }
    .text {
      position: relative;
      margin: 10px 0 0 28px;
      font-size: 14px;
      font-weight: 400;
      color: #3777F6;
      cursor: pointer;
      width: 100px;
      &::after {
        content: "";
        position: absolute;
        left: 60px;
        top: 0;
        width: 20px;
        height: 20px;
        background: url(https://bce.bdstatic.com/p3m/common-service/uploads/arrow_61c51ce.png) no-repeat 0 0;
        background-size: 70px 20px;
      }
    }
  }
}
@keyframes pulse{
  0% {
      transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}
// 移动端布局
@import "@/views/solution/min-solution";
</style>