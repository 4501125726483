<template>
  <div class="company-face">
    <h3 class="title">您的企业正在面临</h3>
    <div class="wrap">
      <div class="card" v-for="(card, index) in list" :key="index">
        <div class="card-title">{{ card.title }}</div>
        <ul class="card-list">
          <li class="card-list-item" v-for="(text, textIndex) in card.textList" :key="textIndex">
            {{ text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CompanyFace",
  data() {
    return {
      list: [
        { title: '流量', textList: [
          '获客成本高',
          '来源平台分散',
          '客户轨迹不全',
          '公私域无打通',
        ] },
        { title: '转化', textList: [
          '成交周期长',
          '客户挖需弱',
          '跟进不及时',
          '客户易流失',
        ] },
        { title: '私域', textList: [
          '粉丝易流失',
          '老客复用难',
          '社群无运营',
          'IP品牌效益弱',
        ] },
        { title: '管理', textList: [
          '协作效率低',
          '经营无抓手',
          '培训成本高',
          '无激励措施',
        ] },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.company-face {
  width: 100%;
  height: 490px;
  background: #F5F7FA;
  padding: 80px 0;
  .title {
    font-weight: 600;
    color: #2D2E30;
    font-size: 32px;
    text-align: center;
    margin: 0 auto 66px;
  }
  .wrap {
    width: 1224px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .card {
      background: url('https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/company-face-card.png') no-repeat;
      background-size: cover;
      width: 282px;
      height: 226px;
      margin: 0 12px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      &-title {
        height: 60px;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 70px;
        text-align: center;
      }
      &-list {
        margin: 20px auto 0;
        list-style: none;
        position: relative;
        left: 10px;
        &-item {
          font-size: 14px;
          font-weight: 400;
          color: #5A5C60;
          margin-bottom: 10px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border: 2px solid #3777F6;
            left: -16px;
            top: 6px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

// 移动端布局
@import '@/views/solution/min-solution';
</style>