<template>
  <div id="app">
    <!-- 页面头部导航 -->
    <sw-header />
    <!-- 页面主体内容 -->
    <div class="app-con">
      <router-view />
    </div>
    <!-- 页面右侧导航 -->
    <sw-right-nav @onConsult="onConsult" />
    <!-- 页面底部导航 -->
    <sw-footer />
    <!-- 移动端底部导航 -->
    <min-phone-nav @onConsult="onConsult" />
  </div>
</template>
<script>
import SwHeader from "@/components/SwHeader.vue"
import SwFooter from "@/components/SwFooter.vue"
import SwRightNav from "@/components/SwRightNav.vue"
import MinPhoneNav from '@/components/MinPhoneNav.vue'
import { mapState } from 'vuex'
export default {
  name: "App",
  components: { SwHeader, SwFooter, SwRightNav, MinPhoneNav },
  data () {
    return {
      pathName: '',
      consultVisible: false
    }
  },
  computed: {
    ...mapState([
      'stayConsultation',
    ]),
  },
  watch: {
    '$route.path' () {
      this.pathName = this.$route.name
      // 切换路由页面回到顶部
      window.scrollTo(0, 0)
    },
    'stayConsultation' (val) {
      if(val){
        this.consultVisible = true
        this.$nextTick(() => {
          this.$refs.consult.init()
        })
      }
    },
  },
  mounted(){
    if (!this.$route.name) {
      this.$router.push({ path: '/' })
    }
    this.pathName = this.$route.name
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll,100);
  },
  methods: {
    onConsult () {
      this.consultVisible = true
      this.$nextTick(() => {
        this.$refs.consult.init()
      })
    }
  }
};
</script>
<style lang="scss">
#app{
  width: 100%;
  min-width: 1200px;
}
.app-con {
  min-height: 1200px;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
}
.homeHeaderFixed {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1001;
}

@media screen and (max-width: 980px) {
  #app{
    width: 100%;
    min-width: 100%;
  }
  .app-con {
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }
  .willingBottomWrapButton1{
    display: inline-block;
    width: 20% !important;
    margin: 0 !important;
    margin-bottom: -0.24rem !important;
    box-shadow: none !important;
    ::before{
      content: "";
      position: absolute;
      top: -0.11rem;
      left: 0.3rem;
      width: 0.2rem;
      height: 0.2rem;
      background-image: url("https://cdn.ideamake.cn/sw-pc/images/consult_icon_phone.png");
      background-size: contain; /* 设置背景图片的缩放模式（可选） */
      background-repeat: no-repeat;
    }
  }
  .willingBottomButton1{
    background-color: white !important;
  }
  .elementText{
    color: #878A90 !important;
    font-size: 0.14rem !important;
  }
}
</style>
