import Vue from "vue";
import VueRouter from "vue-router";
import Home from '@/views/home/Index.vue'
import Solution from '@/views/solution/Index.vue'
import About from '@/views/about/Index.vue'
import Partner from '@/views/partner/Index.vue'
import Cases from '@/views/cases/Index.vue'
import Experience from '@/views/experience/Index.vue'
import Reservation from '@/views/reservation/Index.vue'

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "思为科技首页",
    component: Home,
  },
  {
    path: "/solution",
    name: "解决方案",
    component: Solution,
  },
  {
    path: "/solution/crm",
    name: "销冠CRM",
    component: Solution,
  },
  {
    path: "/solution/online-hall",
    name: "线上展厅",
    component: Solution,
  },
  {
    path: "/solution/transaction-manager",
    name: "交易管理",
    component: Solution,
  },
  {
    path: "/solution/precise-customer",
    name: "全渠道获客",
    component: Solution,
  },
  {
    path: "/solution/channel-manage",
    name: "渠道管理",
    component: Solution,
  },
  {
    path: "/solution/intelligent-operate",
    name: "营销自动化",
    component: Solution,
  },
  {
    path: "/solution/smart-scene",
    name: "智慧案场",
    component: Solution,
  },
  {
    path: "/solution/customer-precipitate",
    name: "社群运营",
    component: Solution,
  },
  {
    path: "/solution/data-insight",
    name: "管理驾驶舱",
    component: Solution,
  },
  {
    path: "/solution/sales-empowerment",
    name: "销售赋能",
    component: Solution,
  },
  {
    path: "/solution/visual-display",
    name: "数字沙盘",
    component: Solution,
  },
  {
    path: "/cases",
    name: "成功案例",
    component: Cases,
  },
  {
    path: '/about',
    name: '关于我们',
    component: About,
  },
  {
    path: '/about',
    name: '关于思为',
    component: About
  },
  {
    path: '/partner',
    name: '合作伙伴',
    component: Partner
  },
  {
    path: '/experience',
    name: '立即体验',
    component: Experience
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: Reservation
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/watch') next()
})

export default router;
