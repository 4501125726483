<template>
  <el-carousel
    height="480px"
    arrow="never"
    class="banner"
    :class="{'banner_crm': $route.path === '/solution/crm'}"
    :indicator-position="currentBanner.length > 1 ? '' : 'none'"
  >
    <el-carousel-item
      v-for="(item, index) in currentBanner"
      :key="'banner' + index"
    >
      <!-- <div class="banner-box" :style="{ 'background-image': `url(${item.image})` }"> -->
      <el-image class="banner-box image" :src="item.image" :alt="item.title"   fit="cover">
      </el-image>
      <el-image
        class="banner-box min-image"
        :src="item.minImage"
        :alt="item.title"
        fit="cover"
      >
      </el-image>
    
      <div class="banner-title left">
        <div class="title-desc">{{ item.titleDesc }}</div>
        <h2>{{ item.title }}</h2>
        <div class="desc">{{ item.desc }}</div>
        <div class="button-bottom">
          <div class="big-button" @click="reservation(item.pathTitle)">
            免费试用
          </div>
          <el-popover
            placement="bottom"
            popper-class="wxQRCode-popper"
            trigger="click"
            v-model="popoverVisible"
          >
            <div class="popover-box">
              <img
                class="wxQRCode-img"
                src="https://cdn.ideamake.cn/sw-pc/images/sw_wx_QR_code-sider.jpeg"
              />
              <div class="title">添加微信</div>
              <div class="text">专属客服将为您介绍更多产品资讯</div>
            </div>
            <div
              class="big-button button-detail"
              @click="advice(item.pathTitle)"
              :style="popoverVisible?{border: '1px solid #74a4f9'}:{}"
              slot="reference"
            >
              立即咨询
            </div>
          </el-popover>
        </div>
      </div>
      <!-- </div> -->
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import utils from '@/utils';
import { bannerList } from '../data';
export default {
  name: 'SolutionBanner',
  data() {
    return {
      pathUrl: '',
      currentBanner: [], // 当前页面banner
      bannerList: bannerList,
      popoverVisible: false,
    };
  },
  watch: {
    '$route.path'() {
      this.pathUrl = utils.changeRoutePath(this.$route.path);
      this.getCurrentBanner();
    },
  },
  mounted() {
    this.pathUrl = utils.changeRoutePath(this.$route.path);
    this.getCurrentBanner();
  },
  methods: {
    getCurrentBanner() {
      this.currentBanner = this.bannerList.filter(
        (item) => item.pathUrl === this.pathUrl
      )[0].banner;
    },
    reservation(title) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `产品与解决方案${title}页顶部免费使用按钮`,
      ]);
      this.$router.push({ name: 'reservation',params:{type: 1,} });
    },
    advice(title) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `产品与解决方案${title}页顶部立即咨询按钮`,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  ::v-deep .el-carousel--horizontal {
    overflow-x: none !important;
  }
}
.banner-box {
  width: 100vw;
  height: 480px;
  position: relative;
  background-size: 100% 480px;
  background-position: 50%;
  background-repeat: no-repeat;
}
.banner-title {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-top: 110px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .title-desc {
    font-size: 20px;
    font-weight: 500;
    color: #3777f6;
    background: #b9d0ff;
    padding: 4px 4px;
    margin-bottom: 8px;
    // display: inline-block;
    width: max-content;
  }
  h2 {
    font-size: 36px;
    font-weight: 500;
    color: #2d2e30;
    padding-bottom: 20px;
    text-align: left;
  }
  .desc {
    font-size: 16px;
    line-height: 28px;
    color: #5a5c60;
    width: 550px;
    text-align: left;
  }
  .button-bottom {
    display: flex;
    .button-detail {
      width: 132px;
      height: 48px;
      background: #ffffff;
      border-radius: 2px;
      color: #3777f6;
      margin-left: 16px;
      &:hover {
        background: #afc9fb;
        color: #ffffff;
        border-color: #3777f6;
      }
      &:active {
        background: #afc9fb;
        color: #ffffff;
        border-color: #3777f6;
      }
    }
  }
}
.big-button {
  width: 132px;
  height: 48px;
  background: #3777f6;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  cursor: pointer;
  text-align: center;
  margin-top: 46px;
  &:hover {
    background: #5f92f8;
  }
}

.banner {
  .min-image {
    display: none;
  }
  ::v-deep .el-carousel__indicators--horizontal {
    width: 1180px;
    bottom: 20px;
    .el-carousel__indicator {
      &.is-active {
        .el-carousel__button {
          background: #3777f6;
        }
      }
    }
    left: 20%;
    display: none;
  }
  ::v-deep .el-carousel__button {
    display: block;
    opacity: 1;
    width: 36px;
    height: 3px;
    background: #fff;
    position: relative;
  }
}
@media screen and (min-width: 1920px) {
  .el-carousel__item {
    text-align: center;
    &_crm {
      background-color: #d0e7ff;
    }
  }
  .banner {
    background-color: #cee1fe;
    &_crm {
      background-color: #d0e7ff;
    }
    .image {
      width: 1920px !important;
    }
    .title {
      text-align: left;
    }
  }
}

// 移动端布局
@import '@/views/solution/min-solution';
</style>
