// 该顺序不能动，是底部按钮下一个方案的顺序依据
export const dataList = [
  { // 下一个方案跳转时用到,但因为crm页面结构不同,用单独另一个配置
    pathUrl: '/solution/crm',
    pathName: '销冠CRM',
    footerSubTitle: '赋能超级个体，成就百万销冠',
    title: '快速构建线上营销阵地，打造流畅的买房卖房体验',
  },
  {
    pathUrl: '/solution/channel-manage',
    pathName: '渠道管理',
    title: '强化企业渠道管控能力，赋能全渠道高效运转',
    dataInfo: [
      {
        direction: 'left',
        title: '全渠道判客',
        subtitle: '更快速智能的渠道判客机制',
        link: '/index',
        list: [
          '灵活设定报备到认购全阶段规则，辅助快速溯源',
          '报备即可实时判客，清晰归属及时跟进',
          '支持发起判客申诉，有效调解归属纠纷',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-four.png',
      },
      {
        direction: 'right',
        title: '自渠拓客',
        subtitle: '精细化自渠管理，提升规模获客转化',
        link: '/index',
        list: [
          '快速搭建渠道体系，外拓人员统一管理',
          '规范客户报备到跟进全流程规则，精细化管控洞察',
          '策渠一体联动，内容/活动有效穿透，客户轨迹实时可循',
          '提供拓客码等多样化推客工具，助力行销拓客',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/channel-manage-one.png',
      },
      {
        direction: 'left',
        title: '分销管理',
        subtitle: '规范分销机构运作流程，提升分销管理效率',
        link: '/index',
        list: [
          '支持多分销机构，统一入口集中管理',
          '不同渠道对应不同规则，灵活设定高效合作',
          '从报备、判客到跟进，透明渠道流程防飞单',
          '渠道数据智能分析，助力优化成交结构',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/channel-manage-two.png',
      },
      {
        direction: 'right',
        title: '全民营销',
        subtitle: '助力规模化低成本获客',
        link: '/index',
        list: [
          '建立经纪人工作台，报备到结佣全流程可视化',
          '搭建经纪人自裂变网络，实现二级分佣',
          '在线快速结佣，激发经纪人推客动力',
          '分享推荐积分赚积分，实现过程激励',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-three.png',
      },
      {
        direction: 'left',
        title: '快速结佣',
        subtitle: '比渠道公司更快、更高效的结佣方式',
        link: '/index',
        list: [
          '线上一键发放，7天快速结佣',
          '全流程可视化，实时跟踪进度',
          '到手佣金更多，推客更有动力',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-six.png',
      },
      {
        direction: 'right',
        title: '渠道风控',
        subtitle: '多重核验预警提示，守好每一道飞单防线',
        link: '/index',
        list: [
          '监控推荐到成交全流程数据，实现8大风险类型校验',
          '客户到访过程及轨迹全程记录，精准判别风险',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-five.png',
      },
    ],
  },
  {
    pathUrl: '/solution/smart-scene',
    pathName: '智慧案场',
    footerSubTitle: '案场服务流程标准化，强化销售过程管控',
    title: '案场服务流程标准化，完成线上交易闭环，强化销售过程管控',
    dataInfo: [
      {
        direction: 'left',
        title: '电子签到',
        subtitle: '更规范、更高效的案场接待模式',
        link: '/index',
        list: [
          '统一来访入口，快速完成全渠道客户登记及判客',
          '无归属客户自动排序，手机端实时通知对应置业顾问',
          '自动关联案场跟客模块，避免漏客藏客',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-one.png',
      },
      {
        direction: 'right',
        title: '客户台账',
        subtitle: '一站式客户管理中心',
        link: '/index',
        list: [
          '多渠道线索一站集成，全方位识别客户',
          '多维度标签画像，定向圈选精准运营',
          '自定义线索视图，客户分层运营',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-eight.png',
      },
      {
        direction: 'left',
        title: '智能工牌',
        subtitle: '一线销讲看得到，案场盘客更高效',
        link: '/index',
        list: [
          'AI还原更真实客户画像，盘客更高效 ',
          '复制销冠能力模型，让优秀销售经验充分流淌',
          '总结分析客户关注点和敏感点，销讲策略迭代升级',
          '自动匹配用户，开机即用流畅体验，极大提升录音覆盖率',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-six.png',
      },
      {
        direction: 'right',
        title: 'GPT盘客',
        subtitle: '给顾问靠谱的跟进建议',
        link: '/index',
        list: [
          'GPT自动生成沟通总结，真实还原客户需求和抗性',
          '智能生成详细跟进建议，优化销售行动策略',
          'AI助手提示关键信息，复盘随时随地、高效便捷',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-nine.png',
      },
    ],
  },
  {
    pathUrl: '/solution/transaction-manager',
    pathName: '交易管理',
    title: '数字化案场交易，助力交易各环节智慧升级',
    dataInfo: [
      {
        direction: 'left',
        title: '在线开盘',
        subtitle: '解决传统开盘所有问题，快速提升开盘效率和规模',
        link: '/index',
        list: [
          '流程简单，极速筹备，最快一天即可开盘',
          '足不出户，微信选房，一键开抢效率飙升',
          '数据看板，实时查看，去化情况一目了然',
          '安全稳定，系统强大，为全流程保驾护航',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-five.png',
      },
      {
        direction: 'right',
        title: '房源管理',
        subtitle: '在线轻松管理所有房源',
        link: '/index',
        list: [
          '房型/楼层/个户信息细分呈现，房源详情清晰呈现',
          '楼盘销售进展直观查看，实时调整销控策略',
          '房源定/调价方案快速制定，折扣方案轻松配置',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-two.png',
      },
      {
        direction: 'left',
        title: '回款管理',
        subtitle: '回款进度实时掌握，回款效率快速提升',
        link: '/index',
        list: [
          '自动生成应收款项，快速生成催款任务 ',
          '欠款催收提醒，加快回款效率',
          '快速收款，自动生成收款单据',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-four.png',
      },
      {
        direction: 'right',
        title: '审批管理',
        subtitle: '各类业务审批在线通关',
        link: '/index',
        list: [
          '自由定义流程走向，多管理员共同协作',
          '各组织间互不干扰，多条审批并行流转',
          '集中管控流程数据，审批记录条条可查',
          '层层节点环环相扣，流转进度实时通知',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-seven.png',
      },
    ],
  },
  {
    pathUrl: '/solution/precise-customer',
    pathName: '全渠道获客',
    title: '内容驱动，实现策销渠一体，千人千面提升全渠道获客体验',
    dataInfo: [
      {
        direction: 'left',
        title: '线上售楼处',
        subtitle: '快速构建线上营销阵地',
        link: '/index',
        list: [
          '全方位小程序线上展厅，移动看房、购房一体化',
          '更懂用户使用习惯和需求，实现品牌价值的极致展示',
          '销售工作台10+种拓客物料，快速覆盖拓客全场景',
        ],
        image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/online-hall-one.png',
      },
      {
        direction: 'right',
        title: '广告投放',
        subtitle: '投放获客的一站式提效解决方案',
        link: '/index',
        list: [
          '主流投放平台全覆盖，打通公域-私域获客通路',
          '线索统一收口，一站式管理所有客源',
          '效果智能分析，提升投放精准度及费效',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/accurate-customer-one.png',
      },
      {
        direction: 'left',
        title: '营销活动',
        subtitle: '优秀活动一键复用，玩法多样快速组合',
        link: '/index',
        list: [
          '7000+高品质活动模板，一键套用快速获客',
          '5级玩法嵌套满足复杂营销需求，契合95%的营销节点',
          '“拖-拉-拽”快速配置活动，上线效率提升4倍以上',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/accurate-customer-three.png',
      },
      {
        direction: 'right',
        title: '电子物料库',
        subtitle: '内容驱动的智能营销引擎',
        link: '/index',
        list: [
          '多样化传播物料，覆盖拓客全场景',
          '一物一码多途径管理，便捷溯源优化',
          '细颗粒度数据采集，获取轨迹挖掘潜客',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/channel-manage-one.png',
      },

    ],
  },
  {
    pathUrl: '/solution/customer-precipitate',
    pathName: '社群运营',
    title: '结合活动+权益，通过场景引导驱动全民转化，提升客户资源利用率',
    footerSubTitle: '多场景驱动全民转化，快速提升客户资源利用率',
    dataInfo: [
      {
        direction: 'left',
        title: '积分商城',
        subtitle: '分享过程激励，低成本拉新促活',
        link: '/index',
        list: [
          '不同角色设置多样任务和积分体系，分层促活',
          '用户转发裂变进行积分激励，提高用户粘性',
          '商城上架实物礼品、卡券等多样化礼品，提升客户活跃度',
          '结合分享报备、全民营销、案场服务、预约场馆，扩展更多业务场景',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/precipitate-two.png',
      },
      {
        direction: 'right',
        title: '会员体系',
        subtitle: '精准激励，梯次权益维系核心角色',
        link: '/index',
        list: [
          '不同角色可独立配置专属会员体系，权益与权利更精准',
          '会员可享受福利、生日礼、购房特权，增加身份尊贵感',
          '可结合积分商城、社群，构建更具费效比的获客方式',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/precipitate-five.png',
      },
      {
        direction: 'left',
        title: '企微运营',
        subtitle: '圈层增长转化解决方案',
        link: '/index',
        list: [
          '客户来源信息打标，快速分配精细化运营',
          '多种智能化群运营能力，自动触达定向客群',
          '群聊数据可视化分析，有效优化运营效率',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/precipitate-one.png',
      },
    ],
  },
  {
    pathUrl: '/solution/intelligent-operate',
    pathName: '营销自动化',
    title: '千万级存量客户分层及自动化运营，全面把握意向加速客户转化',
    dataInfo: [
      {
        direction: 'left',
        title: '营销自动化',
        subtitle: '基于客户行为洞察的智能培育',
        link: '/index',
        list: [
          '整合多营销渠道触达客户，实现关键场景自动牵引 ',
          '通过内容物料获取访问轨迹，自动描摹客户画像',
          '基于客户属性细分定向，精准筛选目标客群',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/operations-four.png',
      },
      {
        direction: 'right',
        title: '客户分析平台',
        subtitle: '精准识别客户意向，辅助营销决策',
        link: '/index',
        list: [
          '基于任务系统+AI云Call，智能快捷识别线索意向',
          '洞悉客群属性和行为，精准预测客户生命周期阶段',
          '基于全渠道数据埋点，生成360度描摹，还原客户真实画像',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/operations-three.png',
      },
      {
        direction: 'left',
        title: 'AI云Call',
        subtitle: '智能外呼高效识别与盘活客户',
        link: '/index',
        list: [
          '简单设置呼叫任务，批量处理上万单沉睡客户',
          '快速筛选客户意向，价值客户一键报备',
          '减少硬件及人力投入，大大降低洗客成本',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/operations-five.png',
      },
 
    ],
  },
  {
    pathUrl: '/solution/data-insight',
    pathName: '管理驾驶舱',
    title: '汇总全域数据可视化查看，助力企业以数据驱动业绩增长',
    footerSubTitle: '全域数据可视化汇总查看，助力企业以数据驱动业绩增长',
    dataInfo: [
      {
        direction: 'left',
        title: 'BI看板',
        subtitle: '为一线提供真实业务指导意义的营销BI',
        link: '/index',
        list: [
          '集成全域数据，打造1+3可视化分析平台，支撑营销决策',
          '数据联动过滤及下钻，分析系统性强',
          '一次建模，长期复用，数据处理效率大大提升',
          '满足团队协作、项目管理、数据协作等多重场景',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/insight-one.png',
      },
      {
        direction: 'right',
        title: '数据报表',
        subtitle: '自助式的BI报表平台',
        link: '/index',
        list: [
          'N个多样化报表模板，营销链路多节点支撑业务决策',
          '灵活拖拽式操作，自由添加、管理数据，大幅降低分析难度，提高汇报沟通效率',
          '各类型数据自定义导出，查询结果图表化，释放分析能力',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/insight-three.png',
      },
    ],
  },
  {
    pathUrl: '/solution/visual-display',
    pathName: '数字沙盘',
    title: '楼盘方寸间，看房零局限',
    footerSubTitle: '快速构建线上营销阵地，打造流畅的买房卖房体验',
    dataInfo: [
      {
        direction: 'left',
        title: 'UE5智慧大屏',
        subtitle: '沉浸式互动体验',
        link: '/index',
        list: [
          '游戏级别的互动感，带给用户超越真实的场景',
          '城市扫描+GIS技术真实还原城市环境',
          '任意漫游查看空间，实时渲染模拟自然光影',
          '虚拟场景帮助开发商提前预售，缩减营销周期',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-three.png',
      },
      {
        direction: 'right',
        title: 'iPad移动售楼系统',
        subtitle: '行走的售楼处',
        link: '/index',
        list: [
          '多元内容形式，满足案场与购房客户深度沟通',
          '随时随地沉浸式看房、选房、游览社区环境体',
          '可置入导出多样化媒体材料，制作成本更低，更新便捷',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/digital-sand-table-two.png',
      },
      {
        direction: 'left',
        title: '微沙盘',
        subtitle: '可视化场景营销利器',
        link: '/index',
        list: [
          '项目价值点极致演绎，充分满足客户看房需求',
          '一键扫码带走微沙盘，扩大传播渠道，降低获客成本',
          '打破低转化率，增大线上流量转化及客户粘性',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-one.png',
      },
      {
        direction: 'right',
        title: '云创编辑器',
        subtitle: '一站式营销物料生产平台',
        link: '/index',
        list: [
          '可视化开发平台，聚焦内容创意，集成流程一目了然',
          '海量优秀案例模板，覆盖多类型地产应用场景',
          '跨平台应用技术，满足平板、大屏、移动端、一体机多平台内容制作',
          '八大内容模块，满足不同营销节点推广需求',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-four.png',
      },
    ],
  },
];

 // 单独配置crm页面,因为他多了一层嵌套
export const crmDataList = [
  {
    pathUrl: '/solution/crm',
    pathName: '销冠CRM',
    title: '快速构建线上营销阵地，打造流畅的买房卖房体验',
    dataInfo: [
      {
        title: '公域获客',
        desc: '连接流量平台，打通公私域，更低成本获客',
        subDataInfo: [
          {
            direction: 'left',
            title: '拓客物料库',
            list: [
              '低门槛开白，快速搭建抖音获客矩阵',
              '自定义灵活配置物料内容，提升生产效率',
              '无需长流程审核，上线即可开启留资',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-one.png',
          },
          {
            direction: 'right',
            title: '抖音获客',
            list: [
              '支持小雪花、小风车接入，线索直通私域',
              '多元直播短视频获客组件，客户无痕留资',
              '私信自动回复，不错漏任何线索',
              '群聊卡片引导，全面捕获粉丝留电通道',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-two.png',
          },
          {
            direction: 'left',
            title: '自动分客',
            list: [
              '无需手动导表，自动分配降低人员成本',
              '实时客资流转，缩短分配时间周期',
              '智能分配客资，支持配置多种分配规则',
              '减少人工介入，保证客资分配公平',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-three.png',
          },
        ]
      },
      {
        title: '线索管理',
        desc: '深度挖掘商机，快速提升跟客转化',
        subDataInfo: [
          {
            direction: 'right',
            title: '智能洗客',
            list: [
              '每发一条朋友圈都是一次洗客动作',
              '细颗粒度行为数据，智能快捷识别线索意向',
              '洞悉客群属性和行为，精准预测客户生命周期阶段',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-five.png',
          },
          {
            direction: 'left',
            title: '客户洞察',
            list: [
              'AI智能算法，识别客户真实需求和购买能力',
              '全渠道数据埋点，生成360度描摹，还原客户真实画像',
            ],
            image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-four.png',
          },
          {
            direction: 'right',
            title: '跟客助手',
            list: [
              '全方位通知客户实时动态，需求响应更及时',
              '未读任务反复提醒，盘客跟客动作无遗漏',
              '销冠跟客模型，更多自动化客户管理经验指导',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-six.png',
          },
        ]
      },
      {
        title: '私域运营',
        desc: '基于内容和社群建立更强信任',
        subDataInfo: [
          {
            direction: 'left',
            title: '微信私域运营',
            list: [
              '支持分享裂变追踪，完成私域精准渗透',
              '覆盖微信多场景，渗透微信社群、朋友圈、微信好友',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-seven.png',
          },
          {
            direction: 'right',
            title: '内容变现',
            list: [
              '官方知识店铺，打造专业IP形象',
              '支持付费购买与学习内容，知识内容商品化',
              '支持在线提问，与房产专家高效建联',
              '专业购房百科资讯，一站式了解同城政策',
            ],
            image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-eight.png',
          },
          {
            direction: 'left',
            title: '粉丝活动运营',
            list: [
              '微信粉丝群多样化活动运营，提升粉丝互动黏性',
              '挖掘头号粉丝，激发分享收割裂变流量',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-nine.png',
          },
          {
            direction: 'right',
            title: '会员运营',
            list: [
              '优惠、会员等多权益互联，盘活私域，扩大流量来源',
              '分阶段储备积分兑换礼品，提高粉丝黏性 ',
              '分享裂变积分激励，低成本拉新促活',
            ],
            image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-ten.png',
          },
        ]
      },
      {
        title: '团队协作',
        desc: '更高效的协作，更充分的激励，更智慧的经营',
        subDataInfo: [
          {
            direction: 'left',
            title: '经营看板',
            list: [
              '识别短视频/直播转化数据，定向提升内容进入率',
              '全链条数据指标看板，有效复盘前端业务成果',
              '支持系统化的业务评估、组织评估、更好实现指标管理',
            ],
            image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-eleven.png',
          },
          {
            direction: 'right',
            title: '成长激励',
            list: [
              '梯级管理，优胜劣汰，支持更加灵活的激励制度',
              '实时团队內部排行榜，荣誉激励激活组织活力',
              '穿透到个人的数据分析，有效赋能多角色能力提升',
            ],
            image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/crm-twelve.png',
          },
        ]
      },
    ],
  }
]

export const bannerList = [
  {
    pathUrl: '/solution/crm',
    pathName: '销冠CRM',
    footerTitle: '销冠CRM',
    banner: [
      {
        titleDesc: '赋能超级个体，成就百万销冠',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/big-banner-crm.png',
        minImage: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/min-big-banner-crm.png',
        title: '销冠CRM | 更智能的营销CRM',
        pathTitle: '销冠CRM',
        desc: '连接流量平台快速获取线索，通过贯穿客户生命周期的洗客、识客、跟客、转化，为一线销售提供更先进的作战工具，助力销冠之路',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/channel-manage',
    pathName: '渠道管理',
    footerTitle: '全渠道高效管理方案',
    banner: [
      {
        titleDesc: '规范管理、精准判客、渠道风控',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-two.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-two.png',
        title: '全渠道高效管理方案',
        pathTitle: '渠道管理',
        desc: '连接内外拓客渠道统一管理，规范渠道对接流程高效判客转化，多种拓客物料便捷分发，渠道数据集成分析，助力企业挖掘最优渠道配比',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/smart-scene',
    pathName: '智慧案场',
    banner: [
      {
        titleDesc: '签到溯源、销冠复制、案场提效',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-four.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-four.png',
        title: '智慧案场数字化方案',
        pathTitle: '智慧案场',
        desc: '赋能客户报备-到访-溯源-判客-交易全流程，支持线上到成交闭环精细化管理，帮助企业高效规范案场服务及销售过程，快速实现案场数字化升级',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/transaction-manager',
    pathName: '交易管理',
    banner: [
      {
        titleDesc: '低成本、易搭建、更先进',
        image:
        'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/big-banner-transaction-manager.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/min-banner-transaction-manager.png',
        title: '房地产轻量化ERP系统',
        pathTitle: '交易管理',
        desc: '数字化案场交易管理，赋能线上交易、房源管控、快速回款、审批流转、在线开盘，实时掌控交易各环节进度，助力交易效率快速提升',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/precise-customer',
    pathName: '全渠道获客',
    banner: [
      {
        titleDesc: '全渠道覆盖、低费效比、高转化率',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-one.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-one.png',
        title: '全渠道精准获客方案',
        pathTitle: '全渠道获客',
        desc: '通过广告投放、内容营销、直播引流、老带新激活、线上展厅搭建等功能打通线上线下，实现策销渠一体，帮助企业全域精准获取高质量线索',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/customer-precipitate',
    pathName: '社群运营',
    banner: [
      {
        titleDesc: '老带新、口碑传播、裂变增长',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-five.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-five.png',
        title: '业主社群运营方案',
        pathTitle: '社群运营',
        desc: '基于社群运营场景，灵活运用积分权益+营销活动+全民营销等多重玩法，挖掘核心业主等KOC，以老带新实现裂变级增长，让成交客户成为口碑传播客户',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/intelligent-operate',
    pathName: '营销自动化',
    banner: [
      {
        titleDesc: '一体化管理、营销自动化、智能标签',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-three.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-three.png',
        title: '客户智能培育方案',
        pathTitle: '营销自动化',
        desc: '打通多端流量平台统一管理公域线索，自动生成客户标签及画像，一客一策分层自动化运营，助力销售实现强效能运营转化',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/data-insight',
    pathName: '管理驾驶舱',
    banner: [
      {
        titleDesc: '最符合业务逻辑，报表统一、灵活生成查看',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-six.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-six.png',
        title: '数据管控赋能方案',
        pathTitle: '管理驾驶舱',
        desc: '采集汇总全域数据，BI看板可视化呈现成交结构、客户情况、服务流程等多维口径，实时分析自动预警，为前端业务提供决策指导',
        link: '/index',
      },
    ],
  },
  // {
  //   pathUrl: '/solution/sales-empowerment',
  //   pathName: '销售赋能',
  //   banner: [
  //     {
  //       titleDesc: '抖音高效拓客、拉通公私域、智能销售助手',
  //       image:
  //         'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-seven.png',
  //       minImage:
  //         'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-seven.png',
  //       title: '知屋｜一线销售爱用好用的业务伙伴',
  //       desc: '连接抖音等流量平台快速公域拓客，多平台客户线索及动态集成，自动画像分层运营，智能助手辅助打标、带看提醒，为一线业务提供更先进的作战工具',
  //       link: '/index',
  //     },
  //   ],
  // },
  {
    pathUrl: '/solution/visual-display',
    pathName: '数字沙盘',
    footerTitle: '移动售楼处',
    banner: [
      {
        titleDesc: '极致展示、实时交互、流畅体验',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-eight.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-eight.png',
        title: '一站式可视化的场景营销方案',
        desc: '结合VR、UE等前沿视觉技术，打破时空限制，通过三维场景、VR连接带看为客户提供全新视觉体验，助力企业提升楼盘效率、拓客获客边界',
        link: '/index',
      },
    ],
  },
];
