import { swTrackerReport } from '@/tracker.js'
export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      setTimeout(() => {
        swTrackerReport('mp_pageshow', vm.$route.path, vm.$route.name, vm.$route.query)
      }, 800)
    })
  },
  beforeRouteLeave (to, from, next) {
    swTrackerReport('mp_pagehide', this.$route.path, this.$route.name, this.$route.query)
    next()
  }
}
