import http from './request'

export default {
  // 提交留资信息
  submitCustomerForm (sig, params) {
    return http.post(
      `/customer-relation/customer/official-website?sig=${sig}`,
      params,
      { headers: { 'Content-Type': 'application/json' } }
    )
  },
}

