<template>
  <div class="honor-page honor">
    <h3 class="title">公司荣誉</h3>
    <div class="container">
      <div
        class="wrapper"
        @mouseover="onPause"
        @mouseout="onMove"
        :style="{ marginLeft: `${direction * transformX}px` }"
      >
        <div
          ref="wrapperItemRef"
          class="wrapper-item"
          v-for="(honorItem, i) in carouselList"
          :key="i"
        >
          <div class="img-box">
            <el-image
              class="image"
              :src="honorItem.srcUrl"
              :fit="honorItem.fit"
              :alt="honorItem.title"
              :preview-src-list="[honorItem.srcUrl]"
            ></el-image>
          </div>
          <div class="wrapper-item-content">
            <p class="title">{{ honorItem.title }}</p>
            <p class="content">{{ honorItem.content }}</p>
          </div>
        </div>
      </div>
  
      <div class="marquee-footer">
        <div class="marquee-footer-left action" @click="onLeft">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="marquee-footer-right action" @click="onRight">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'AboutHonor',
  data() {
    return {
      carouselList: [
        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_01.png',
          content: '广东省“专精特新”企业',
          title: '专精特新',
          fit: 'cover',
        },
        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_02.png',
          content: '国家高新技术企业认证',
          title: '高新企业',
          fit: 'cover',
        },
        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_03.png',
          content: '产品生态集成认证',
          title: '阿里云认证',
          fit: 'cover',
        },
        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_04.png',
          content: '华为&鲲鹏技术认证',
          title: '华为云认证',
          fit: 'cover',
        },

        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_05.png',
          content: '中国领先地产科技企业50',
          title: '毕马威',
          fit: 'cover',
        },

        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_06.png',
          content: '地产数字力TOP20科技领军企业',
          title: '克尔瑞',
          fit: 'cover',
        },

        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_07.png',
          content: '穿越严峻时期的最强AI企业',
          title: '机器之心金炼奖',
          fit: 'cover',
        },
        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_08.png',
          content: '多次入选爱分析行业报告',
          title: '爱分析',
          fit: 'cover',
        },

        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_09.png',
          title: '腾讯产业加速器',
          content: '腾讯SaaS加速器首期成员',
          fit: 'cover',
        },

        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_10.png',
          title: '深圳创新榜',
          content: '深圳最具投资价值企业50强',
          fit: 'cover',
        },

        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_11.png',
          title: '德勤',
          content: '深圳高科技高成长20强企业',
          fit: 'cover',
        },

        {
          srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/honor_12.png',
          title: '中国地产金砖奖',
          content: '创新技术大奖',
          fit: 'cover',
        },
      ],
      sliderState: {
        paused: false,
        space: 0,
        reverse: false,
      },
      perTime: 1800, //每个项运动的时间
      timer: null,
      transformX: 0,
      wrapperItemRef: null,
      wrapperWidth: 0,
      stop: null,
      start: null,
      currentN: 0,
      totalTime: 72000,
      duration: 10,
      direction: -1
    };
  },
  computed: {

    noramlDis() {     
      console.log(this.wrapperWidth / (this.totalTime / this.duration),this.wrapperWidth)
      return this.wrapperWidth / (this.totalTime / this.duration);
    },
  },
  methods: {
    onPause() {
      if (this.timer) { 
        clearInterval(this.timer)
      }
    },
    onMove() {
      this.autoMove();
    },
    onLeft() {
      if (this.timer) { 
        clearInterval(this.timer)
      }
      let dis = 0.1
  
      this.timer = setInterval(() => {
        dis += 0.1
        if (dis >= 15) {
          clearInterval(this.timer)
          this.autoMove()
        }
        //  console.log(this.transformX,dis,this.noramlDis,'dis')
        this.transformX = this.transformX - this.noramlDis - dis;
        if (this.transformX <= this.wrapperWidth) {
          this.transformX =  this.wrapperWidth + this.transformX
        }
        
      }, this.noramlDis);
    },
    onRight() {
      if (this.timer) { 
        clearInterval(this.timer)
      }
      let dis = 0.1
  
      this.timer = setInterval(() => {
        dis += 0.1
        if (dis >= 15) {
          clearInterval(this.timer)
          this.autoMove()
        }
        
        this.transformX = this.transformX + this.noramlDis + dis;

        // console.log( this.transformX,dis,'dis')
        if (this.transformX >= this.wrapperWidth * 2) {
          this.transformX =  this.wrapperWidth
        }
        
      }, this.noramlDis);
    },
    
    autoMove() { 
      if (this.timer) {
        clearInterval(this.timer)
      }

      // console.log(this.noramlDis,'this.noramlDis')
      this.timer = setInterval(() => {
        this.transformX = this.transformX + this.noramlDis;
        if (this.transformX >= this.wrapperWidth * 2) {
          this.transformX = this.wrapperWidth
        }
    
      }, this.noramlDis);
    }



  },
  created() {
    // 为了能够无缝滚动，先复制一份
    this.carouselList = [...this.carouselList,...this.carouselList, ...this.carouselList];
  },
  mounted() {
    // 一共12个有效
    const actualLen = this.carouselList.length / 3;
    const itemDom = this.$refs.wrapperItemRef[0];
    const itemMarginRight = parseInt(
      window.getComputedStyle(itemDom, null).getPropertyValue('margin-right')
    );
    const itemWidth = itemDom.getBoundingClientRect().width + itemMarginRight;
    this.wrapperWidth = itemWidth * actualLen;
    this.transformX = this.wrapperWidth;
    this.totalTime = actualLen * 9000 //  每个item需要9000
    // console.log('initValue:  1824', this.transformX)
    this.autoMove();
  },
  unmounted() {
    if (this.timer) { 
      clearInterval(this.timer)
    }
  },
};
</script>

<style lang="scss" scoped>
.honor-page {
  width: 100%;
  background: #eaeff6;
  padding-top: 80px;
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 48px;

  .title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
  }
  .container {
    // width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    padding: 0 24px 32px 24px;
    overflow: hidden;
    .wrapper {
      display: flex;
      // justify-content: start;
      // padding: 0 80px;
      padding-left: 0px;
      color: #2d2e30;
      text-align: left;
      width: fit-content;
      &-item {
        width: 280px;
        height: 322px;
        padding: 2px;
        background: #ffffff;
        box-shadow: 0px 4px 12px 0px rgba(155, 165, 192, 0.25);
        border-radius: 5px;
        margin-right: 24px;
        font-size: 14px;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0px;
        }
        .img-box {
          width: 100%;
          height: 224px;
          overflow: hidden;
          margin-bottom: 16px;
          background: #e1e8f7;
        }
        .image {
          width: 100%;
        }
        p {
          text-align: left;
        }
        &-content {
          color: #2d2e30;
          padding: 0 12px;
          .title {
            font-weight: 500;
            font-size: 16px;
          }
          .content {
            font-weight: 400;
            font-size: 14px;
            margin-top: 6px;
          }
        }
      }
    }

    .marquee-footer {
      margin-top: 36px;
      display: flex;
      justify-content: center;
      .action {
        width: 50px;
        height: 50px;
        background: #fff;
        box-shadow: 0 4px 12px 0 rgba(155, 165, 192, 0.25);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          i {
            color: #3777f6;
          }
        }
        i {
          font-size: 20px;
          color: #888b90;
          font-weight: 900;
        }
      }
      &-left {
        margin-right: 48px;
      }
    }

    &.min-phone {
      display: none;
    }
  }
}

// 移动端布局
@import '@/views/about/min-about';
</style>
