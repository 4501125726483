import tracker from '@ideamake/sw-tracker-web'
import { apiUrl, trackerSocketUrl } from  '@/api/config.js'
import router from '@/router'
import utils from '@/utils'

export default {
  install (Vue) {
    Vue.use(tracker)
    Vue.prototype.$tracker.init({
      domain: 'marketingCloudGW',
      appType: 'web',
      debug: false,
      websocketUrl: `${trackerSocketUrl}/zeus-one-push/webNewAESSocket/`,
      errorUrl: `${apiUrl}/log-collect/log/upload`,
      router,
    })

    const trackerInfo = {
      tenant_id: 'swkj',
      user_id: sessionStorage.getItem('ip_address'),
      mobile: 'swkj',
      nickname: 'swkj',
      user_type: 'swkj',
      city: sessionStorage.getItem('info_address'),
    }
    Vue.prototype.$tracker.updateUser(trackerInfo)
    Vue.prototype.$tracker.updatePlatPub({ app_id:'swkj' })
  }
}

/**
 * sw-tracker手动上报
 * @param {*} event
 * @param {*} pathName
 * @param {*} query
 */
 export const swTrackerReport = (event, pathPath, pathName, query) => {
  tracker.report({
    eventName: event, // 事件名 mp_pageshow（进入页面） mp_pagehide（离开页面）
    eventType: 'visit', // 事件类型 golbal全局事件 click点击事件  visit访问事件
    event_location_type: 'page', // 事件位置属性
    event_location: {
      page_path: 'swkjGW' + pathPath, // 当前页面路径
      page_query: utils.qs.stringify(query), // 当前页面路径参数
      route_name: pathName // 当前页面标题
    },
  })
}

/**
 * sw-tracker手动上报
 * @param {*} event
 * @param {*} pathName
 * @param {*} query
 */
export const swTrackerClickReport = (event, pathModule, pathName) => {
  tracker.report({
    eventName: event, // 事件名
    eventType: 'click', // 事件类型 golbal全局事件 click点击事件  visit访问事件
    event_location_type: 'page', // 事件位置属性
    event_location: {
      route_name: pathName // 当前页面标题
    },
    properties: {
      page_module: pathModule, // 当前页面模块
    }
  })
}
