<template>
  <div>
    <!-- pc端 -->
    <nav
      :class="['header', { 'header-scrolled': scrollShow }]"
      @mouseover="navMouseover"
      @mouseout="navMouseout"
    >
      <div class="nav-box">
        <div class="nav-left">
          <!-- logo -->
          <div class="logo-box">
            <router-link to="/">
              <img class="img-logo" :src="logoImg" />
            </router-link>
          </div>
          <!-- 路由导航 -->
          <div class="nav-list">
            <el-menu
              v-if="menuShow"
              ref="menuRef"
              :default-active="pathUrl"
              :router="true"
              class="el-menu-demo"
              mode="horizontal"
              background-color="rgba(0,0,0,0)"
              :text-color="menuTextColor"
              active-text-color="#3777F6"
              popper-class="nav-pup"
              @select="handleSelect"
            >
              <el-menu-item index="/">首页</el-menu-item>
              <el-menu-item
                index="/solution/crm"
                class="solution-sub"
              >
                <span class="solution-sub-title">
                  销冠CRM<img
                    style="width: 36px; height: 16px"
                    src="https://cdn.ideamake.cn/sw-pc/images/web3.0/hot-icon.png"
                    alt=""
                  />
                </span>
              </el-menu-item>
              <el-submenu
                class="solution-main"
                index="/solution"
                :popper-class="solution"
                :popper-append-to-body="false"
                :hide-timeout="0"
              >
                <template slot="title">｜产品与解决方案</template>
                <section class="solution-popup" >
                  <!-- 占位 -->
                  <div style="flex: 1;background-color: #F1F2F5;"></div>
                  <!-- 左侧轮播 -->
                  <section class="solution-popup-left">
                    <div class="solution-wrap" v-for="(item, index) in solutionTitleList" :key="index" @mouseenter="mouseOver(index)" @click="selectSolutionTitle(item)">
                      <img class="icon" :src="item.icon" :alt="item.title">
                      <div class="content" :class="{'content_active': index === carouselIndex}">
                        <div class="title">
                          {{ item.title }}
                          <img
                            v-if="item.hot"
                            style="width: 36px; height: 16px"
                            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/hot-icon.png"
                            alt=""
                          />
                        </div>
                        <div class="description">{{ item.description }}</div>
                      </div>
                    </div>
                    <img class="building" src="https://cdn.ideamake.cn/sw-pc/images/web3.0/header-title-building.png" alt="">
                  </section>
                  <!-- 右侧导航 -->
                  <section class="solution-popup-right">
                    <div class="solution-search">
                      <el-input
                        class="solution-search-input"
                        placeholder="搜索方案名称"
                        @focus="solutionSearchContentShow = true"
                        @blur="solutionSearchBlur"
                        @input="solutionSearch"
                        v-model.trim="solutionSearchValue"
                      >
                        <img slot="suffix" src="https://cdn.ideamake.cn/sw-pc/images/web3.0/icon-search.png" alt="" />
                      </el-input>
                      <!-- 搜索下拉框 -->
                      <transition name="slide-fade">
                        <div v-if="solutionSearchContentShow" class="solution-search-content">
                          <!-- 暂无结果 -->
                          <div v-if="solutionSearchValue && !solutionSearchList.length" class="content-empty">
                            <div class="content-empty-header">
                              暂无搜索结果
                            </div>
                            <div class="content-empty-title">
                              热门产品<img src="https://cdn.ideamake.cn/sw-pc/images/web3.0/hot-icon.png" alt="热门产品">
                            </div>
                            <div class="content-empty-hot">
                              <span 
                                v-for="(item, index) in solutionHotList" 
                                :key="index" 
                                class="tag" 
                                @click.stop="handleSolutionSearch(item)"
                              >
                                {{ item.text }}
                              </span>
                            </div>
                          </div>
                          <!-- 搜索结果/热门 -->
                          <div v-else class="content-result">
                            <div v-show="!solutionSearchValue" class="content-result-title">热门产品</div>
                            <div 
                              v-for="(item, index) in solutionSearchValue ? solutionSearchList : solutionHotList" 
                              :key="index"
                              class="content-result-item"
                              @click="handleSolutionSearch(item)"
                            >
                              <span >{{ item.text }}</span>
                            </div>
                          </div>
                        </div>
                      </transition>
                      <div v-for="(item) in solutionDefaultSearchList" :key="item.text" class="solution-search-tag" @click.stop="handleSolutionSearch(item)">{{ item.text }}</div>
                    </div>
                    <el-carousel v-if="true" ref="carousel" height="374px" direction="vertical" indicator-position="none" :initial-index="carouselIndex" :autoplay="false">
                      <el-carousel-item v-for="(solutionTitle)  in solutionTitleList" :key="solutionTitle.name">
                        <div class="solution-content">
                          <el-menu-item
                            v-for="(item, index) in solutionTitle.children"
                            :key="index"
                            :style="item.style || {}" 
                          >
                            <el-menu-item
                              :style="myStyle(item.icon)"
                              class="solution-title"
                              :index="item.code"
                              @click="trackerData(item.title)"
                            >
                              {{ item.specialTitle || item.title }}
                              <img
                                v-if="item.hot"
                                style="width: 36px; height: 16px"
                                src="https://cdn.ideamake.cn/sw-pc/images/web3.0/hot-icon.png"
                                alt=""
                              />
                            </el-menu-item>
                            <el-menu-item
                              v-for="(list, textIndex) in item.content"
                              :key="textIndex"
                              :style="item.direction === 'horizontal' ? { display: 'inline-block', minWidth: 0, marginRight: '40px', width: 'auto !important' } : {}"
                              :index="solutionTitle.name === 'crm' ? item.code + `&subIndex=${textIndex}` : item.code + `?index=${textIndex}`"
                              @click="goPage({ name: list.text }, true)"
                            >
                              {{ list.text }}
                              <img
                                v-if="list.hot"
                                style="width: 36px; height: 16px"
                                src="https://cdn.ideamake.cn/sw-pc/images/web3.0/hot-icon.png"
                                alt=""
                              />
                            </el-menu-item>
                          </el-menu-item>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </section>
                  <!-- 占位 -->
                  <div style="flex: 1;background-color: #fff;"></div>
                </section>
              </el-submenu>
              <el-menu-item index="/cases">成功案例</el-menu-item>
              <el-menu-item index="/watch" @click="goSWG">思为观</el-menu-item>

              <el-menu-item index="/about">关于思为</el-menu-item>


              <!-- <el-submenu
                index="/about"
                :popper-class="popperClass"
                :popper-append-to-body="false"
                :hide-timeout="0"
              >
                <template slot="title">关于我们</template>
                <el-menu-item index="/about">关于思为</el-menu-item>
                <el-menu-item @click="goJoin">加入我们</el-menu-item>
              <el-menu-item index="/partner">合作伙伴</el-menu-item> 
              </el-submenu> -->
               
            </el-menu>
          </div>
        </div>
        <!-- 电话、立即体验 -->
        <div class="nav-right">
          <div class="phone">
            <img class="phone-img" :src="phoneImg" />
            <!-- :style="{ color: this.scrollShow || this.back ? '#2D2E30' : '' }" -->
            <span
              :style="{ color:'#2D2E30'}"
              >400-8075-618</span
            >
          </div>
          <div class="go-experience" @click="goReservation">立即体验</div>
        </div>
      </div>
    </nav>
    <!-- 移动端 -->
    <nav class="min-header-box">
      <div class="min-header">
        <div class="min-logo-box">
          <img
            class="img-logo"
            src="https://cdn.ideamake.cn/sw-pc/images/header_logo_black.png"
          />
        </div>
        <div class="min-nav-right">
          <img
            class="min-nav-icon min-nav-phone"
            @click="callPhone"
            src="https://cdn.ideamake.cn/sw-pc/images/min-phone/min_phone.png"
          />
          <img
            class="min-nav-icon min-nav-list"
            @click="showPopup"
            src="https://cdn.ideamake.cn/sw-pc/images/min-phone/min_list.png"
          />
        </div>
      </div>
      <!-- 下拉导航栏 -->
      <van-popup v-model="minNavShow" position="top">
        <div class="min-nav-popup" :style="{marginBottom: itemPopupShow ? '1.32rem' : 0}">
          <ul class="navpopup-list">
            <li
              :class="['popup-list-item', { active: pathUrl === '/' }]"
              @click="goRoute('/')"
            >
              首页
            </li>
            <li
              :class="[
                'popup-list-item',
                'popup',
                { 'popup-show': itemPopupShow },
                { active: pathUrl.indexOf('/solution') !== -1 },
              ]"
              @click="showItemPopup"
            >
              产品与解决方案
            </li>
            <div class="item-popup solution" v-show="itemPopupShow">
              <div v-for="(solutionTitle, index) in solutionTitleList" :key="index" class="item-popup-wrap">
                <div class="popup-text-title" :style="{'margin-bottom': index < 2 ? 0 : '.1456rem'}">{{ solutionTitle.title }}</div>
                <template v-if="solutionTitle.name !== 'digitalSandTable'">
                  <span
                    v-for="(solution, solutionIndex) in solutionTitle.children" 
                    :key="solutionIndex"
                    :class="[
                      'popup-text-item',
                      { active: $route.fullPath === solution.code },
                    ]"
                    @click="goRoute(solution.code)"
                  >
                    {{ solution.title }}
                  </span>
                </template>
                <template v-else>
                  <!-- 数字沙盘 -->
                  <span
                    :class="[
                      'popup-text-item',
                      { active: $route.fullPath === '/solution/visual-display?index=0' },
                    ]"
                    @click="goRoute('/solution/visual-display?index=0')"
                  >
                    UE5智慧大屏
                  </span>
                  <span
                    :class="[
                      'popup-text-item',
                      { active: $route.fullPath === '/solution/visual-display?index=1' },
                    ]"
                    @click="goRoute('/solution/visual-display?index=1')"
                  >
                    IPad移动售楼系统
                  </span>
                  <span
                    style="margin-right: 0;"
                    :class="[
                      'popup-text-item',
                      { active: $route.fullPath === '/solution/visual-display?index=2' },
                    ]"
                    @click="goRoute('/solution/visual-display?index=2')"
                  >
                    微沙盘
                  </span>
                  <span
                    :class="[
                      'popup-text-item',
                      { active: $route.fullPath === '/solution/visual-display?index=3' },
                    ]"
                    @click="goRoute('/solution/visual-display?index=3')"
                  >
                    云创编辑器
                  </span>
                </template>
              </div>      
            </div>
            <li
              :class="['popup-list-item', { active: pathUrl === '/cases' }]"
              @click="goRoute('/cases')"
            >
              成功案例
            </li>
            <!-- <li class="popup-list-item" @click="goSWG">思为观</li> -->

            <li
              :class="['popup-list-item', { active: pathUrl === '/about' }]"
              @click="goRoute('/about')"
            >
            关于我们
            </li>
          </ul>
          <!-- <div class="go-experience" @click="goReservation">立即体验</div> -->
        </div>
      </van-popup>
    </nav>
  </div>
</template>

<script>
import { jsonp } from 'vue-jsonp';
import utils from '@/utils';
import { solutionList, solutionDefaultSearchList } from '@/components/constant'

export default {
  name: 'SwHeader',
  components: {},
  data() {
    return {
      observer: null,
      scrollShow: false, // 页面滚动
      pathUrl: '/', // 首页
      menuShow: true,
      minNavShow: false,
      itemPopupShow: false,
      aboutPopupshow: false, // 关于我们下拉
      solutionSearchContentShow: false, // 产品与解决方案搜索内容框展示
      carouselShow: false,
      state2: '',
      solutionList,
      solutionSearchValue: '',
      solutionSearchList: [],
      solutionDefaultSearchList,
      carouselIndex: 0, // PC端轮播当前轮播页
    }
  },
  computed: {
    logoImg() {
      // return this.scrollShow || this.back
      //   ? 'https://cdn.ideamake.cn/sw-pc/images/header_logo_black.png'
      //   : 'https://cdn.ideamake.cn/sw-pc/images/header_logo_white.png';
      return 'https://cdn.ideamake.cn/sw-pc/images/header_logo_black.png'
    },
    phoneImg() {
      // return this.scrollShow || this.back
      //   ? 'https://cdn.ideamake.cn/sw-pc/images/phone_black.png'
      //   : 'https://cdn.ideamake.cn/sw-pc/images/phone_white.png';
      return 'https://cdn.ideamake.cn/sw-pc/images/phone_black.png'
    },
    // menuBackgroundColor() {
    //   return this.scrollShow ? '#FFFFFF' : 'rgba(0,0,0,0)';
    // },
    menuTextColor() {
      // return this.scrollShow || this.back ? '#2D2E30' : '#FFFFFF';

      return '#2D2E30'

    },
    popperClass() {
      return this.scrollShow ? 'header-nav-popup scrolled' : 'header-nav-popup';
    },
    solution() {
      return 'header-nav-popup scrolled solution';
    },
    myStyle() {
      return (val) => {
        // icon 有可能为undefined，this.solutionList有{}主要用于占位
        if (!val) { 
          return {}
        }
        return `--my-var: url(${val}); background-image: var(--my-var); background-repeat: no-repeat; background-size: 16px 16px;`;
      };
    },
    back() {
      let data = true;
      if (this.pathUrl === '/about' || this.pathUrl === '/cases' ) {
        data = false;
      }
      return data;
    },
    // 带有hot标签的菜单
    solutionHotList () {
      const list = this.solutionList.reduce((prev, cur) => {
        return prev.concat((cur.content ?? []).map((subMenu, index) => ({
          ...subMenu,
          index,
          title: cur.title,
          text: subMenu.text,
          code: cur.code,
          parentName: cur.parentName
        })).filter(subMenu => subMenu.hot))
      }, [])
      return this.getRandomValues(list, 6)
    },
    // 产品与解决方案菜单左边4个大菜单
    solutionTitleList () {
      const list = [
        {
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-title-crm.png',
          title: '销冠CRM',
          description: '更智能的营销CRM',
          name: 'crm',
          hot: true,
        },
        {
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-title-smart.png',
          title: '智慧案场',
          description: '营销一体化数字案场解决方案',
          name: 'smartField',
        },
        {
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-title-cloud.png',
          title: '营销云',
          description: '做最好的营销CRM',
          name: 'marketCloud',
        },
        {
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-title-sand.png',
          title: '数字沙盘',
          description: '楼盘方寸间，看房零局限',
          name: 'digitalSandTable',
        },
      ]
      return list.map(item => {
        item.children = this.solutionList.filter(menu => menu.parentName === item.name)
        return item
      })
    }
  },
  watch: {
    '$route.path'() {
      this.pathUrl = utils.changeRoutePath(this.$route.path);
      // 刷新组件防止选中BUG
      if (['/experience', '/reservation'].includes(this.pathUrl)) {
        this.menuShow = false;
        setTimeout(() => {
          this.menuShow = true;
          this.$nextTick(() => {
            const targetElement = document.querySelector('.el-menu--horizontal.header-nav-popup.scrolled.solution')
            this.observer.disconnect()
            this.observer.observe(targetElement, { attributes: true, attributeFilter: ['style'] });
          })
        }, 1);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    this.observer.disconnect()
  },
  created() {
    this.pathUrl = utils.changeRoutePath(this.$route.path);
    window.addEventListener('scroll', this.handleScroll);
    this.getLocation();
  },

  mounted() {
    // 监听
    this.observer = new MutationObserver(utils.debounce((mutationsList) => {
      if (mutationsList.length) {
        const mutation = mutationsList[0]
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const targetElement = mutation.target;
          const displayValue = targetElement.style.display;
          this.carouselShow = displayValue !== 'none'
        }
      }
    }, 50));
    const targetElement = document.querySelector('.el-menu--horizontal.header-nav-popup.scrolled.solution')
    this.observer.observe(targetElement, { attributes: true, attributeFilter: ['style'] });
  },
  methods:{
    handleSelect(key, path) {
      switch (path) {
        case ['/']:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏首页',
          ]);
          break;
        case ['/cases']:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏成功案例',
          ]);
          break;
        case ['/watch']:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏思为观',
          ]);
          break;
        case ['/about', '/about']:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏关于我们关于思为',
          ]);
          break;
      }
    },
    trackerData(title) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `头部导航栏产品与解决方案下拉菜单二级导航${title}`,
      ]);
    },
    filterSolutionListByKeyWord (keyword, solutionList) {
      let list = []
      let firstMenuParentName = ''
      let firstMenuCode = ''
      const findKeyWord = (curList, isFirstMenu = true, keyConfig) => {
        curList.forEach((menu, index) => {
          if (menu[keyConfig.label]) {
            const { matchCount, target } = this.fuzzySearch(keyword, menu[keyConfig.label])
            if (isFirstMenu) {
              firstMenuParentName = menu.parentName
              firstMenuCode = menu.code
            }
            if (matchCount > 0) list.push({
              ...menu,
              matchCount, 
              text: target,
              isFirstMenu,
              index,
              code: firstMenuCode,
              parentName: firstMenuParentName
            })
            if (Array.isArray(menu[keyConfig.children]) && menu[keyConfig.children].length) {
              findKeyWord(menu[keyConfig.children], false, { label: 'text', children: 'content' })
            }
          }
        })
      }
      findKeyWord(solutionList, true, { label: 'title', children: 'content' })
      list.sort((a, b) => b.matchCount - a.matchCount)
      return list
    },
    handleSolutionSearch(item) {
      if (item.text === this.solutionSearchValue) return
      this.solutionSearch(item.text)
      if (item.isFirstMenu) {
        this.goPage({ path: item.code })
      } else {
        this.goPage({ path: item.code, query: item.parentName === 'crm' ? { subIndex: item.index } : { index: item.index } })
      }
      // 关闭菜单
      this.$nextTick(() => {
        this.$refs.menuRef.close('/solution')
      })
    },
    solutionSearchBlur () {
      // 太快关闭会使得列表消失而导致点击事件失效
      setTimeout(() => {
        this.solutionSearchContentShow = false
        this.solutionSearchValue = ''
      }, 100);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 获取当前定位并存储【后续使用】
    getLocation() {
      var data = { key: 'WDTBZ-EOPRG-5ONQY-IDVMO-NXIIK-C4B7A' };
      var url = 'https://apis.map.qq.com/ws/location/v1/ip'; //这个就是地理位置信息的接口
      data.output = 'jsonp';
      jsonp(url, data)
        .then((res) => {
          const ipAddress = res.result.ip;
          const ad_info = res.result.ad_info;
          const { nation, province, city, district } = ad_info;
          const addressInfo = nation + province + city + district;
          sessionStorage.setItem('ip_address', ipAddress);
          sessionStorage.setItem('info_address', addressInfo);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    navMouseover() {
      this.scrollShow = true;
    },
    navMouseout() {
      this.scrollShow = window.pageYOffset === 0 ? false : true;
    },
    handleScroll() {
      this.scrollShow = window.pageYOffset > 0 ? true : false;
    },
    selectSolutionTitle (item) {
      if (['digitalSandTable', 'crm'].includes(item.name)) {
        this.$router.push({ path: item.children?.[0]?.code.split('?')[0] });
      }
    },
    // 跳转思为观
    goSWG() {
      window.open('https://research.ideamake.cn/', '_blank')
    },
    // 跳转加入我们
    goJoin() {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        '头部导航栏关于我们加入我们',
      ]);
      window.open('https://ideamake.zhiye.com/home', '_blank');
    },
    // 跳转立即体验
    goExperience() {
      this.minNavShow = false;
      this.$router.push({ path: '/experience' });
    },
    // 跳转预约演示
    goReservation() {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        '头部导航栏立即体验按钮',
      ]);
      this.$router.push({ path: '/reservation', params: { type: 1 } });
     
    },
    // 跳转
    goPage(data, isOnlyTrack = false) {
      // console.log(data,'data')
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `头部导航栏产品与解决方案下拉菜单三级导航${data.name}`,
      ]);
      !isOnlyTrack && this.$router.push(data);
    },
    // 模糊搜索
    fuzzySearch(keyword, target) {
      // 将关键词转换为小写，以便不区分大小写
      keyword = keyword.toLowerCase();
      target = target.toLowerCase()
      let matchCount = 0;
      // 遍历目标字符串数组
      for (let i = 0; i < target.length; i++) {
        const singleTarget = target[i];
        // 检查目标字符串是否包含关键词的每个字符
        for (let j = 0; j < keyword.length; j++) {
          if (singleTarget.indexOf(keyword[j]) !== -1) {
            matchCount++;
            break
          }
        }
      }
      // 返回排序后的目标字符串数组
      return { matchCount, target }
    },
    solutionSearch: utils.debounce(function (keyWord) {
      this.solutionSearchList = this.filterSolutionListByKeyWord(keyWord, this.solutionList)
    }, 30),
    getRandomValues(array, count) {
      array = [...array]
      const result = []
      for (let i = 0; i < count; i++) {
        const randomIndex = Math.floor(Math.random() * array.length)
        const randomValue = array.splice(randomIndex, 1)[0]
        result.push(randomValue)
      }
      return result;
    },

    // 移动端配置
    // 调用拨号功能
    callPhone() {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        '移动端顶部手机图标按钮',
      ]);
      window.location.href = 'tel://' + '400-8075-618';
    },
    showPopup() {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        '移动端顶部菜单图标按钮',
      ]);
      this.minNavShow = !this.minNavShow;
    },
    goRoute(routePath) {
      console.log(
        '%c [  ]-605',
        'font-size:13px; background:pink; color:#bf2c9f;',
        routePath
      );
      switch (routePath) {
        case '/':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏首页',
          ]);
          break;
        case '/cases':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏成功案例',
          ]);
          break;
        case '/about':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏关于我们关于思为',
          ]);
          break;
        case '/solution/crm':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航销冠CRM',
          ]);
          break;
        case '/solution/precise-customer':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航全渠道获客',
          ]);
          break;
        case '/solution/channel-manage':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航渠道管理',
          ]);
          break;
        case '/solution/intelligent-operate':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航营销自动化',
          ]);
          break;
        case '/solution/smart-scene':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航智慧案场',
          ]);
          break;
        case '/solution/customer-precipitate':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航社群运营',
          ]);
          break;
        case '/solution/data-insight':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航管理驾驶舱',
          ]);
          break;
        case '/solution/sales-empowerment':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航销售赋能',
          ]);
          break;
        case '/solution/visual-display':
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '头部导航栏产品与解决方案下拉菜单二级导航视觉展示',
          ]);
          break;
      }
      console.log(
        '%c [  ]-609',
        'font-size:13px; background:pink; color:#bf2c9f;',
        routePath
      );
      this.minNavShow = false;
      this.$router.push({ path: routePath });
    },
    showItemPopup() {
      this.itemPopupShow = !this.itemPopupShow;
    },
    showAboutPopup() {
      this.aboutPopupshow = !this.aboutPopupshow;
    },
    mouseOver (index) {
      this.carouselIndex = index
      this.$refs.carousel.setActiveItem(index)
    },
  },
};
</script>

<style lang="scss" scoped>
nav.header {
  width: 100%;
  height: 62px;
  overflow: hidden;
  padding: 4px 0 0 0;
  box-sizing: border-box;
  position: fixed;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  z-index: 500;
  &.header-scrolled {
    padding: 0;
    background: #ffffff;
    color: #2d2e30;
    box-shadow: 0 0 4px rgb(0 0 0 / 10%);
  }
}
.nav-box {
  max-width: 1200px;
  min-width: 900px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .nav-left {
    display: flex;
    align-items: center;
    .logo-box {
      width: auto;
      height: 30px;
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        color: #fff;
      }
      .img-logo {
        width: auto;
        height: 28px;
      }
    }
    .nav-list {
      margin-left: 50px;
      height: 62px;
      width: 100%;
      .solution {
        &-main {
          ::v-deep {
            .el-submenu__title {
              padding: 0 0 0 5px;
            }
          }
        }
        &-popup {
          display: flex;
          background-color: #F1F2F5;
          height: 100%;
          &-left {
            width: 304px;
            padding: 32px 0 0 40px;
            position: relative;
            .building {
              width: 179px;
              position: absolute;
              bottom: 0;
              right: 12px;
              z-index: 0;
            }
          }
          &-right {
            width: 896px;
            height: 100%;
            background-color: #FFFFFF;
            ::v-deep .el-carousel__item {
              transition: none;
            }
          }
        }
        &-sub {
          padding: 0 0 0 20px;
          &-title {
            display: flex;
            align-content: center;
            align-items: center;
          }
        }
        &-wrap {
          display: flex;
          margin-bottom: 28px;
          position: relative;
          z-index: 10;
          cursor: pointer;

          .icon {
            width: 48px;
            height: 48px;
            display: block;
          }
          .content {
            margin-left: 16px;
            color: #878A90;
            font-size: 12px;
            font-weight: 400;
            &.content_active {
              color: #2D2E30;
              .title {
                color: #3777F6;
              }
            }
            .title {
              color: #2D2E30;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 4px;
            }
          }
        }
      }
      ::v-deep .el-menu {
        border: none;
        .el-menu-item {
          height: 62px;
          line-height: 62px;
          font-weight: 500;
          border-bottom-color: rgba(0, 0, 0, 0) !important;
          &.is-active {
            border-bottom: none;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0) !important;
            color: #3777f6 !important;
          }
        }
        .el-submenu {
          width: 132px;
          &:hover{
            color: #3777f6 !important;
          }
          .el-submenu__title {
            height: 62px;
            line-height: 62px;
            font-weight: 500;
            border-bottom-color: rgba(0, 0, 0, 0) !important;
            &:hover {
              background-color: rgba(0, 0, 0, 0) !important;
              color: #3777F6 !important;
            }
            .el-submenu__icon-arrow.el-icon-arrow-down {
              display: none;
            }
          }
          &.is-active {
            .el-submenu__title {
              border-bottom: none;
            }
          }
    
        }
      }
    }
  }
  .nav-right {
    display: flex;
    align-items: center;
    .phone {
      display: flex;
      align-items: center;
      font-weight: 500;
      .phone-img {
        width: 17px;
        height: 16px;
        margin-right: 6px;
      }
    }
    .go-experience {
      cursor: pointer;
      margin-left: 32px;
      width: 88px;
      height: 32px;
      background: #3777f6;
      line-height: 32px;
      text-align: center;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      &:hover {
        background: #5f92f8;
      }
    }
  }
  .solution-search {
    position: relative;
    display: flex;
    margin: 32px 0 24px 40px;
    &-input {
      width: 370px;
      font-size: 14px;
      ::v-deep .el-input__inner {
        color: #2D2E30;
        font-weight: 400;
      }
      img {
        width: 14px;
        height: 14px;
        margin: 9px 5px 0 0;
      }
    }
    &-content {
      width: 370px;
      height: 247px;
      background-color: #fff;
      position: absolute;
      z-index: 999;
      left: 0;
      top: 36px;
      box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      overflow: auto;
      padding: 6px 0 12px 0;
      .content-empty {
        padding: 0 16px;
        &-header {
          padding: 16px 0;
          font-size: 14px;
          font-weight: 400;
          color: #878A90;
          border-bottom: 1px solid #D9DCE3;
        }
        &-title {
          padding: 32px 0 16px 0;
          font-size: 14px;
          font-weight: 400;
          color: #5A5C60;
          img {
            width: 36px;
            height: 16px;
            margin-left: 5px;
          }
        }
        &-hot {
          display: flex;
          flex-wrap: wrap;
          .tag {
            width: 40%;
            display: block;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 500;
            color: #2D2E30;
              &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .content-result {
        &-title {
          width: 100%;
          padding: 5px 0 5px 16px;
          margin-top: 6px;
          font-size: 14px;
          font-weight: 400;
          color: #5A5C60;
        }
        &-item {
        width: 100%;
        height: 32px;
        padding: 5px 0 5px 16px;
        margin-top: 6px;
        font-size: 14px;
        font-weight: 400;
        color: #2D2E30;
        transition: all 0.12s ease;
          &:hover {
            background-color: #EFF4FF;
            color: #3777F6;
            cursor: pointer;
          }
        }
      }
    }
    &-tag {
      height: 32px;
      background: #F1F2F5;
      border-radius: 2px;
      padding: 0 12px;
      margin-left: 10px;
      color: #2D2E30;
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
        color: #ffffff;
        background-color: #3777F6;
      }
    }
    .slide-fade-enter-active, .slide-fade-leave-active  {
      transition: all .15s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to {
      height: 0;
    }
  }
}
@import '@/assets/scss/min-index';
</style>
