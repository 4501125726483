<template>
  <div class="cases-con">
    <Banner></Banner>
    <cases-ka />
    <Cases-item :showNumber="showNumber"/>
    <customer-recognition></customer-recognition>
    <digital-marketing></digital-marketing>
    <marketing-upgrade></marketing-upgrade>
  </div>
</template>

<script>
import Banner from "./components/Banner.vue"
import CasesKa from "./components/CasesKa.vue"
import CasesItem from "@/components/CasesItem.vue"
import customerRecognition from "@/components/CustomerRecognition.vue"
import digitalMarketing from "@/components/DigitalMarketing.vue"
import marketingUpgrade from "@/components/MarketingUpgrade.vue"
import swTracker from '@/mixins/tracker.js'

export default {
  name: "CasesView",
  mixins: [swTracker],
  components: {
    Banner,
    CasesKa,
    CasesItem,
    customerRecognition,
    digitalMarketing,
    marketingUpgrade,

  },
  data() {
    return {
      browserWigth: 0,
    };
  },
  computed: {
    showNumber () {
      return this.browserWigth > 980 ? 6 : 4
    },
  },
  created(){
    window.addEventListener('resize', this.getBrowserWidth)
    this.getBrowserWidth()
  },
  destroyed(){
    window.removeEventListener('resize', this.getBrowserWidth)
  },
  methods: {
    // 获取浏览器宽度
    getBrowserWidth(){
      this.browserWigth = window.innerWidth
    }
  },
};
</script>
<style lang="scss" scoped>
.cases-con {
  width: 100%;
  height: 100%;
  // .banner {
  //   position: relative;
  //   height: 480px;
  //   width: 100%;
  //   background: url('https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/banner-case-01.png');
  //   background-size: 100% 480px;
  //   background-position: 50%;
  //   background-repeat: no-repeat;
  //   &-text {
  //     position: absolute;
  //     width: 1200px;
  //     left: 0;
  //     top: 40%;
  //     right: 0;
  //     bottom: 0;
  //     margin: 0 auto;
     
  //     .title{
  //       font-size: 42px;
  //       font-weight: 600;
  //     }
  //     .text{
  //       margin-top: 20px;
  //       font-size: 16px;
  //       font-weight: 400;
  //       color: #5A5C60;
  //     }
     
  //   }
  // }
}

// 移动端布局
@import "@/views/cases/min-cases";
</style>
