export const solutionList = [
  {
    title: '公域获客',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-10.png',
    content: [
      { text: '拓客物料库', hot: false },
      { text: '抖音获客', hot: true },
      { text: '自动分客', hot: true },
    ],
    code: '/solution/crm?index=0',
    parentName: 'crm',
    hot: true
  },
  {
    title: '线索管理',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-11.png',
    content: [
      { text: '智能洗客', hot: true },
      { text: '客户洞察', hot: true },
      { text: '跟客助手', hot: false },
    ],
    code: '/solution/crm?index=1',
    parentName: 'crm',
    hot: true
  },
  {
    title: '私域运营',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-12.png',
    content: [
      { text: '私域裂变', hot: true },
      { text: '内容变现', hot: false },
      { text: '粉丝活动运营', hot: false },
      { text: '会员运营', hot: false },
    ],
    code: '/solution/crm?index=2',
    parentName: 'crm'
  },
  {
    title: '团队协作',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-13.png',
    content: [
      { text: '经营看板', hot: false },
      { text: '成长激励', hot: false },
    ],
    code: '/solution/crm?index=3',
    parentName: 'crm'
  },
  {
    title: '渠道管理',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-two.png',
    content: [
      { text: '全渠道判客', hot: false },
      { text: '自渠拓客', hot: false },
      { text: '分销管理', hot: false },
      { text: '全民营销', hot: true },
      { text: '快速结佣', hot: true },
      { text: '渠道风控', hot: false },
    ],
    code: '/solution/channel-manage',
    parentName: 'smartField',
    hot: false
  },
  {
    title: '智慧案场',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/header-icon-four.png',
    content: [
      { text: '电子签到', hot: false },
      { text: '客户台账', hot: false },
      { text: '智能工牌', hot: true },
      { text: 'GPT盘客', hot: false },
    ],
    code: '/solution/smart-scene',
    parentName: 'smartField',
  },
  {
    title: '交易管理',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/header-icon-nine.png',
    content: [
      { text: '在线开盘', hot: false },
      { text: '房源管理', hot: false },
      { text: '回款管理', hot: false },
      { text: '审批管理', hot: false },
    ],
    code: '/solution/transaction-manager',
    parentName: 'smartField',
    hot: true
  },
  {
    title: '全渠道获客',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-one.png',
    content: [
      { text: '线上售楼处', hot: false },
      { text: '广告投放', hot: false },
      { text: '营销活动', hot: true },
      { text: '电子物料库', hot: false },
    ],
    code: '/solution/precise-customer',
    parentName: 'marketCloud',
    hot: true,
  },
  {
    title: '社群运营',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-five.png',
    content: [
      { text: '积分商城', hot: true },
      { text: '会员体系', hot: false },
      { text: '企微运营', hot: false },
    ],
    code: '/solution/customer-precipitate',
    parentName: 'marketCloud',
    hot: true,
  },
  {
    title: '营销自动化',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-three.png',
    content: [
      { text: '营销自动化', hot: true },
      { text: '客户分析平台', hot: false },
      { text: 'AI云Call', hot: false },
    ],
    code: '/solution/intelligent-operate',
    parentName: 'marketCloud',
    hot: true,
  },
  {
    title: '管理驾驶舱',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-six.png',
    content: [
      { text: 'BI看板', hot: false },
      { text: '数据报表', hot: false },
    ],
    code: '/solution/data-insight',
    parentName: 'marketCloud',
    hot: true,
  },
  {
    title: '数字沙盘',
    icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-eight.png',
    content: [
      { text: 'UE5智慧大屏', hot: true, icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-14.png', },
      { text: 'IPad移动售楼系统', hot: true, icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-15.png', },
      { text: '微沙盘', hot: true, icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-eight.png', },
      { text: '云创编辑器', hot: false, icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/header-icon-16.png', },
    ],
    code: '/solution/visual-display',
    parentName: 'digitalSandTable',
    direction: 'horizontal'
  },
]

export const solutionDefaultSearchList = [
  { text: '销冠CRM', isFirstMenu: true, code: '/solution/crm' },
  { text: '抖音', index: 1, code: '/solution/crm?index=0', parentName: 'crm' },
  { text: '社群运营', isFirstMenu: true, code: '/solution/customer-precipitate' },
  { text: '案场管理', isFirstMenu: true, code: '/solution/smart-scene' },
  { text: 'UE5', index: 0, code: '/solution/visual-display' },
]