<template>
  <div class="about">
    <Banner></Banner>

    <!-- 侧边导航 -->
    <div class="left-nav" v-show="leftNavShow && activeName !== '公司荣誉'">
      <el-tabs
        tab-position="left"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="公司简介" name="公司简介"></el-tab-pane>
        <el-tab-pane label="企业文化" name="企业文化"></el-tab-pane>
        <el-tab-pane label="发展历程" name="发展历程"></el-tab-pane>
        <el-tab-pane label="公司荣誉" name="公司荣誉"></el-tab-pane>
        <el-tab-pane label="联系我们" name="联系我们"></el-tab-pane>
        <el-tab-pane label="加入我们" name="加入我们"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 公司简介 -->
    <div class="panel scroll-item company-intro" style="background: #fff">
      <div class="panel-content">
        <div class="intro">
          <div class="intro-left">
            <h3 class="main-title">思为科技</h3>
            <h4 class="subtitle">
              <span> 地产数字化营销领导者</span>
              <img
                src="https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-intro-us.png"
                alt=""
                srcset=""
              />
            </h4>
            <p class="text">
              深圳思为科技有限公司，成立于2011年，是一家致力于用领先技术驱动不动产营销数字化升级的SaaS服务商，聚焦营销获客-连接-洞察-运营-转化全链路，从消费端重塑业务流程，推动行业从以“货”为中心的“信息化”，向以“人”为中心的“数字化”迈进。围绕用户增长，用户洞察，和用户运营，拉通策销渠一体，打造整套的客户全生命周期管理系统。
            </p>
            <p class="text">
              目前，思为科技产品在百强国央企开发商覆盖率超90%，在千亿级开发商覆盖率超95%，并与超过半数的TOP20开发商达成集团、区域营销数字化的深度合作。
            </p>
          </div>

          <div class="intro-data">
            <div
              class="intro-data-item"
              v-for="(item, index) in datas"
              :key="index"
            >
              <div class="count">
                <span class="value">{{ item.value }}</span>
                <span class="suffix">{{ item.suffix }}</span>
              </div>
              <div class="label">{{ item.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="panel scroll-item culture">

      <div class="panel-content">
        <h2 class="title">企业文化</h2>
        <about-culture />
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="panel scroll-item course" style="padding-bottom: 75px">
      <img
          src="https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-history-bg-01.png"
          alt="企业使命"
          srcset=""
          class="history-page-bg"
        />
      <div class="course_bag">

        <div class="panel-content">
          <h2 class="title">发展历程</h2>
          <about-history />
        </div>
      </div>
    </div>

    <!-- 公司荣誉 -->
    <about-honor class="scroll-item" />
    <!-- 联系我们 -->
    <about-address class="scroll-item" />
    <!-- 加入我们 -->
    <about-join-us class="scroll-item" />
    <!-- 加入我们 -->
    <!-- <about-more class="scroll-item" /> -->
  </div>
</template>

<script>
import AboutAddress from './components/Address.vue';
import AboutHonor from './components/Honor.vue';
// import AboutMore from './components/More.vue';
import AboutCulture from './components/Culture.vue';
import AboutHistory from './components/History.vue';
import AboutJoinUs from './components/JoinUs.vue';
import swTracker from '@/mixins/tracker.js';
import Banner from './components/Banner.vue';
export default {
  name: 'AboutView',
  mixins: [swTracker],
  components: {
    AboutJoinUs,
    AboutAddress,
    AboutHonor,
    // AboutMore,
    AboutCulture,
    AboutHistory,
    Banner,
  },
  data() {
    return {
      leftNavShow: false,
      activeName: '公司简介',
      AboutIntro01: 'https://cdn.ideamake.cn/sw-pc/images/about-intro-01.png',
      AboutBanner01:
        'https://cdn.ideamake.cn/sw-pc/images/banner/banner-about-01.png',
      AboutBg01: 'https://cdn.ideamake.cn/sw-pc/images/about-bg-01.png',

      datas: [
        {
          value: 31,
          label: '国家专利',
          suffix: '项',
        },
        {
          value: 37,
          label: '国家软件著作权',
          suffix: '项',
        },
        {
          value: 40,
          label: '行业权威奖项',
          suffix: '+',
        },
        {
          value: 95,
          label: '千亿房企覆盖率',
          suffix: '%',
        },
        {
          value: 600,
          label: '服务客户',
          suffix: '+',
        },
        {
          value: 6400,
          label: '覆盖案场数',
          suffix: '',
        },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const lis = document.querySelectorAll('.scroll-item');
      this.leftNavShow =
        window.pageYOffset > 200 && window.pageYOffset < lis[5].offsetTop - 50
          ? true
          : false;
      if (window.pageYOffset < lis[0].offsetTop) {
        this.activeName = '公司简介';
      } else if (window.pageYOffset < lis[1].offsetTop) {
        this.activeName = '企业文化';
      } else if (window.pageYOffset < lis[2].offsetTop) {
        this.activeName = '发展历程';
      } else if (window.pageYOffset < lis[3].offsetTop) {
        this.activeName = '公司荣誉';
      } else if (window.pageYOffset < lis[4].offsetTop) {
        this.activeName = '联系我们';
      } else if (window.pageYOffset < lis[5].offsetTop) {
        this.activeName = '加入我们';
      }
    },
    handleClick(tab) {
      const index = Number(tab.index);
      switch (index) {
        case 0:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '关于我们关于思为左侧导航公司简介',
          ]);
          break;
        case 1:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '关于我们关于思为左侧导航企业文化',
          ]);
          break;
        case 2:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '关于我们关于思为左侧导航发展历程',
          ]);
          break;
        case 3:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '关于我们关于思为左侧导航公司荣誉',
          ]);
          break;
        case 4:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '关于我们关于思为左侧导航联系我们',
          ]);
          break;
        case 5:
          window._hmt.push([
            '_trackEvent',
            'button',
            'click',
            '关于我们关于思为左侧导航加入我们',
          ]);
          break;
      }
      const lis = document.querySelectorAll('.scroll-item');
      this.scrollAnimate(lis[index].offsetTop - 80);
    },
    scrollAnimate(scrollHeight) {
      window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 响应式媒体查询
@media screen and (max-width: 1500px) {
  .about {
    .left-nav {
      display: none;
    }
  }
}
.about {
  width: 100%;
  height: 100%;
  .left-nav {
    position: fixed;
    width: 100px;
    height: 500px;
    left: calc(50% - 740px);
    top: 280px;
    z-index: 100;
  }

  .panel {
    // min-height: 630px;
    background: #f7f9fb;
    padding-bottom: 80px;
    &.culture{
      background-color: #EAEFF6;
    }
    &.course {
      // height: 600px;
      position: relative;
      // background: #f5f7fa;
      background-color: transparent;
      .history-page-bg{
        position: absolute;
        // object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

      }
      // .course_bag {
     
      // }
    }
    &.company-intro{
      padding-bottom: 50px;
    }
    &-content {
      width: 1200px;
      margin: 0 auto;
      .title {
        padding-top: 80px;
        font-size: 32px;
        font-weight: 500;
        color: #2d2e30;
        text-align: center;
      }
      .intro {
        // display: flex;
        margin-top: 60px;

        color: #2d2e30;
        &-left {
          padding-right: 40px;
          .main-title {
            font-size: 48px;
            font-weight: 600;
            text-align: left;
          }
          .subtitle {
            font-size: 30px;
            font-weight: 500;
            margin: 0px 0 32px 0;
            position: relative;
            // width: 347px;
            height: 53px;
            display: flex;
            align-items: flex-end;
            span {
              z-index: 1;
            }
            img {
              position: absolute;
              // width: 100%;
              height: 100%;
              top: -4px;
            }
          }
          .text {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 16px;
            color: #5a5c60;
          }
        }

        &-data {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 30px;
          &-item {
            width: 200px;
            height: 108px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .count {
              font-size: 42px;
              font-weight: bold;
              color: #2d2e30;
            }
            .label {
              font-size: 16px;
              font-weight: 400;
              color: #5a5c60;
            }
            .value {
              font-family: DINAlternate-Bold, DINAlternate;
            }
            .suffix {
              color: #2d2e30;
              font-size: 16px;
              font-weight: 400;
              margin-left: 4px;
              font-family: PingFangSC-Regular, PingFang SC;
            }
          }
        }
      }
    }
  }
}

// 移动端布局
@import '@/views/about/min-about';
</style>
