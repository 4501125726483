<template>
  <div class="role-support">
    <h3 class="title">角色支持</h3>
    <div class="wrap">
      <div class="card" v-for="(card, index) in list" :key="index">
        <div class="card-title" :class="`card-title-${index + 1}`">{{ card.title }}</div>
        <ul class="card-list">
          <li class="card-list-item" v-for="(text, textIndex) in card.textList" :key="textIndex">
            {{ text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RoleSupport",
  data() {
    return {
      list: [
        { title: '开发商', textList: [
          '自渠',
          '置业顾问',
          '销售经理',
        ] },
        { title: '中介渠道', textList: [
          '主播',
          '选房师',
          '管理员',
        ] },
        { title: '独立经纪人', textList: [
          '夫妻店',
          '独立房产主播',
        ] },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.role-support {
  width: 100%;
  height: 486px;
  background: #EAEFF6;
  padding: 80px 0;
  .title {
    font-weight: 500;
    color: #2D2E30;
    font-size: 32px;
    text-align: center;
    margin: 0 auto 64px;
  }
  .wrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card {
      width: 376px;
      height: 222px;
      background: linear-gradient(180deg, #F7F8FA 0%, #FAFBFD 100%);
      box-shadow: 0px 4px 12px 0px rgba(155,165,192,0.25), inset 0px 1px 3px 0px rgba(0,0,0,0.03);
      &-title {
        width: 100%;
        height: 60px;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        &-1 {
          background: linear-gradient(90deg, #6D9EFF 0%, #3777F6 100%);
          + .card-list {
            padding-left: 154px;
          }
        }
        &-2 {
          background: linear-gradient(90deg, #32B9FF 0%, #00A4FF 100%);
          + .card-list {
            padding-left: 164px;
          }
        }
        &-3 {
          background: linear-gradient(90deg, #4F6CA5 0%, #284785 100%);
          + .card-list {
            padding-left: 140px;
          }
        }
      }
      &-list {
        list-style: none;
        padding: 32px 0 0 154px;
        &-item {
          font-size: 16px;
          font-weight: 500;
          color: #2D2E30;
          margin-bottom: 16px;
          position: relative;
          &::before {
            content: '';
            background-image: url('https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/role-support-check.png');
            background-size: cover;
            position: absolute;
            width: 19px;
            height: 18px;
            left: -36px;
            top: 2px;
          }
        }
      }
    }
  }
}

// 移动端布局
@import "@/views/solution/min-solution";
</style>