<template>
  <div class="experience">
    <div class="home-con">
      <div class="home-con-item">
        <div class="first-word" style="padding: 50px 0 0 15px;">
          <div class="first-word-button">营销云</div>
          <div class="first-word-big">
            无需注册，<br>
            码上体验专为房企打造的官方营销入口
          </div>
          <div class="first-word-small">
            <div class="first-word-small-item">
              品牌介绍：给客户更多必买理由
            </div>
            <div class="first-word-small-item">
              推荐客户：玩转全民营销
            </div>
            <div class="first-word-small-item">
              购房优惠：活动裂变拉新引流
            </div>
            <div class="first-word-small-item">
              我要咨询：告别电话销售的尴尬
            </div>
            <div class="first-word-small-item">
              积分商城：提高用户粘性
            </div>
            <div class="first-word-small-item">
              直播广场：随时随地开团看房
            </div>
            <div class="first-word-small-item">
              超多功能，尽在思为科技营销云
            </div>
          </div>
          <div class="first-word-big-button">
            温馨提示： <br>
            1. 将小程序设置为【我的小程序】，下次使用会更方便哦 <br>
            2. 想解锁更多功能，欢迎您主动联系我们！（官方服务热线：400-8075-618）
          </div>
        </div>
        <div class="hide-img-box">
          <img loading="lazy" class="first-img" src="https://cdn.ideamake.cn/sw-pc/images/experience-now-one.png" alt="">
        </div>
      </div>
    </div>
    <div class="home-con-box2">
      <div class="home-con" style="padding-top: 95px;">
        <div class="home-con-item">
          <div class="hide-img-box">
            <img loading="lazy" class="first-img" src="https://cdn.ideamake.cn/sw-pc/images/experience-now-two.png" alt="">
          </div>
          <div class="first-word">
            <div class="first-word-button">微沙盘</div>
            <div class="first-word-big">
              无需注册， <br>
              码上体验思为科技微沙盘
            </div>
            <div class="first-word-small">
              <div class="first-word-small-item">
                三维沙盘；语音讲房；样板间；
              </div>
              <div class="first-word-small-item">
                区位交通；电子楼书；户型图；
              </div>
              <div class="first-word-small-item">
                码上带走掌心智慧案场
              </div>
            </div>
            <div class="first-word-big-button">
              温馨提示： <br>
              1. 智慧案场包含手机端，iPad端，大屏等多应用场景，只需一次开发，为置业顾问讲解增效 <br>
              2. 更多精美案场动画，尽在标杆案例中展示 <br>
              3. 想了解更多智慧案场，欢迎您主动联系我们！（官方服务热线：400-8075-618）
            </div>
          </div>
          <div class="hide-img-box-min-phone">
            <img loading="lazy" class="first-img" src="https://cdn.ideamake.cn/sw-pc/images/experience-now-two.png" alt="">
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import swTracker from '@/mixins/tracker.js'

export default {
  name: "ExperienceView",
  mixins: [swTracker],
  data () {
    return {
    }
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
 .experience{
    background-color: #FFFFFF;
    .home-con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 152px;
        height: auto;
        .home-con-item{
          display: flex; 
          justify-content: space-between;
          .first-word {
            padding: 70px 0 0 15px;
            .first-word-big{
              color: #2D2E30;
              font-size: 28px;
              font-weight: 600;
              margin-bottom: 20px;
            }
            .first-word-button{
              width: 66px;
              height: 30px;
              background: #4E88FC;
              border-radius: 2px;
              font-weight: 400;
              color: #FFFFFF;
              font-size: 14px;
              text-align: center;
              line-height: 30px;
              margin-bottom: 40px;
            }
            .first-word-small{
              font-weight: 400;
              color: #2D2E30;
              font-size: 14px;
              width: 378px;
              margin-bottom: 80px;
              .first-word-small-item{
                  margin-bottom: 5px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #5A5C60;
              }
            }
            .first-word-big-button{
              font-size: 14px;
              font-weight: 400;
              color: #878A90;
            }
          }
          .hide-img-box{
            width: 514px;
            height: 600px;
            overflow: hidden;
          }
          .first-img{
              width: 514px;
              height: 640px;
          }
      }
    }
  }
  .home-con-box2{
    background-color: #F4F6F8;
    .home-con{
      margin: 0 auto;
      width: 1200px;
    }
    .first-word{
      padding: 120px 0 0 15px !important;
      .first-word-button{
        background: #9780F5 !important;
      }
    }
    .first-word-small{
      margin-bottom: 90px !important;
    }
    .hide-img-box-min-phone{
      display: none;
    }
  }
  
// 移动端适配
@import "@/assets/scss/min-index";
</style>
