<template>
  <div class="join-page join">
    <img
      src="https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-join-bg.png"
      alt="加入我们"
      srcset=""
      class="join-img"
      fit="cover"
    />
    <img
      src="https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-join-min-bg.png"
      alt="加入我们"
      srcset=""
      class="join-img join-min-img"
      fit="cover"
    />
    <h3 class="title">加入我们</h3>
    <p class="desc">
      如果有人邀请你上一艘火箭，你不要问上去之后坐哪儿，你只要上去就可以了
    </p>
    <el-button type="primary" @click="onStart" class="btn">即刻登机</el-button>
  </div>
</template>

<script>
export default {
  name: 'AboutJoinUs',
  methods: {
    onStart() {
      window.open('https://ideamake.zhiye.com/home', '_blank');
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        '关于我们/加入我们底部即刻登机',
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.join-page {
  width: 100%;
  // min-height: 380px;
  height: 250px;
  padding-top: 40px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  .join-img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 250px;
    top: 0;
    left: 0;
  }
  .join-min-img{
    display: none;
  }

  .title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
  }
  .desc {
    margin-top: 16px;
    color: #2D2E30;
    font-size: 18px;
  }
  .btn {
    // width: 1200px;
    // margin: 0 auto;
    margin-top: 40px;
    width: 132px;
    height: 48px;
    background: #3777f6;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400;
    &:hover{
      background: #5f92f8;
    }
  }
}

// 移动端布局
@import '@/views/about/min-about';
</style>
