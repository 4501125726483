<template>
  <transition :name="transitionName">
    <div v-show="visible" :style="customStyle" class="back-to-ceiling" @click="backToTop">
      <div class="icon">
        <i class="el-icon-download"></i>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    visibilityHeight: {
      type: Number,
      default: 400
    },
    backPosition: {
      type: Number,
      default: 0
    },
    customStyle: {
      type: Object,
      default: function() {
        return {
          right: '10px',
          bottom: '80px',
          width: '50px',
          height: '50px',
        }
      }
    },
    transitionName: {
      type: String,
      default: 'fade'
    }
  },
  data() {
    return {
      visible: false,
      interval: null,
      isMoving: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    handleScroll() {
      this.visible = window.pageYOffset > this.visibilityHeight
    },
    backToTop() {
      if (this.isMoving) return
      const start = window.pageYOffset
      let i = 0
      this.isMoving = true
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500))
        if (next <= this.backPosition) {
          window.scrollTo(0, 0)
          clearInterval(this.interval)
          this.isMoving = false
        } else {
          window.scrollTo(0, next)
        }
        i++
      }, 16.7)
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return c / 2 * t * t + b
      return -c / 2 * (--t * (t - 2) - 1) + b
    }
  }
}
</script>

<style lang="scss" scoped>
.back-to-ceiling {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  &:hover{
    .icon{
      i{
        color: #fff !important;
      }
      background-color: #3777f6;
    }
  }
}
.icon{
  width:60px;
  height:32px;
  text-align: center;
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.03), 0px 15px 35px -2px rgba(0,0,0,0.05);
  background: #FFFFFF;
  border-radius: 2px 0px 0px 2px;
  background-size:contain;
  line-height: 32px;
  transform: rotate(180deg);
  i{
    font-size: 18px;
    font-weight: 600;
    color: #878A90;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0
}

// 响应式媒体查询
@media screen and (max-width: 980px) {
  .back-to-ceiling {
    &:hover{
    .icon{
        i{
          color: #878A90;
        }
      }
    }
  }
}
</style>
