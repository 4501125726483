<template>
  <footer class="footer">
    <!-- PC端 -->
    <div class="footer-box">
      <div class="footer-nav-list">
        <div class="nav-list-item">
          <div class="logo">
            <img
              src="https://cdn.ideamake.cn/sw-pc/images/header_logo_white.png"
              alt="logo"
              srcset=""
            />
          </div>
          <div class="contact">
            <div class="product">
              <span class="label">产品咨询：</span>
              <span class="value">400-8075-618</span>
            </div>
            <div class="cooperate">
              <span class="label">市场合作：</span>
              <span class="value">marketing@ideamake.cn</span>
            </div>
          </div>
          <div class="wxQRCode">
            <div
              class="wxQRCode-item"
              v-for="item in wxQRCodeArr"
              :key="item.url"
            >
              <img class="wxQRCode-img" :src="item.url" />
              <p class="wxQRCode-desc">{{ item.label }}</p>
            </div>
          </div>
        </div>
        <div class="nav-list-item">
          <div class="item-title">产品与解决方案</div>
          <div class="item-list two-item">
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案销冠CRM']);"
                to="/solution/crm"
                >销冠CRM</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案智慧案场']);"
                to="/solution/channel-manage"
                >智慧案场</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案营销云']);"
                to="/solution/precise-customer"
                >营销云</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案数字沙盘']);"
                to="/solution/visual-display"
                >数字沙盘</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案公域获客']);"
                to="/solution/crm?index=0"
                >公域获客</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案渠道管理']);"
                to="/solution/channel-manage"
                >渠道管理</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案全渠道获客']);"
                to="/solution/precise-customer"
                >全渠道获客</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案UE5智慧大屏']);"
                to="/solution/visual-display?index=0"
                >UE5智慧大屏</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案线索管理']);"
                to="/solution/crm?index=1"
                >线索管理</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案智慧案场']);"
                to="/solution/smart-scene"
                >智慧案场</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案社群运营']);"
                to="/solution/customer-precipitate"
                >社群运营</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案iPad移动售楼系统']);"
                to="/solution/visual-display?index=1"
                >iPad移动售楼系统</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案私域运营']);"
                to="/solution/crm?index=2"
                >私域运营</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案交易管理']);"
                to="/transaction-manager"
                >交易管理</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案营销自动化']);"
                to="/intelligent-operate"
                >营销自动化</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案微沙盘']);"
                to="/solution/visual-display?index=2"
                >微沙盘</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案团队合作']);"
                to="/solution/crm?index=3"
                >团队合作</router-link
              >
            </div>
            <div class="list-li"></div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案管理驾驶舱']);"
                to="/solution/data-insight"
                >管理驾驶舱</router-link
              >
            </div>
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案云创编辑器']);"
                to="/solution/visual-display?index=3"
                >云创编辑器</router-link
              >
            </div>
          </div>
        </div>
        <div class="nav-list-item">
          <div class="item-title">成功案例</div>
          <div class="item-list">
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部成功案例']);"
                to="/cases"
                >成功案例</router-link
              >
            </div>
            <!-- <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部成功案例营销云']);" to="/cases">智慧案场</router-link>
            </div> -->
          </div>
        </div>
        <div class="nav-list-item">
          <div class="item-title">思为观</div>
          <div class="item-list">
            <div class="list-li">
              <a
                href="https://research.ideamake.cn/page/2783719?navIndex=1"
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们优质案例']);"
                target="_blank"
                >优质案例</a
              >
            </div>
            <div class="list-li">
              <a
                href="https://research.ideamake.cn/page/2113946?navIndex=2"
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们活动专区']);"
                target="_blank"
                >活动专区</a
              >
            </div>
            <div class="list-li">
              <a
                href="https://research.ideamake.cn/page/2113153?navIndex=3"
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们精品课程']);"
                target="_blank"
                >精品课程</a
              >
            </div>
            <div class="list-li">
              <a
                href="https://research.ideamake.cn/page/2137348?navIndex=4"
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们行业干货']);"
                target="_blank"
                >行业干货</a
              >
            </div>
            <!-- <div class="list-li">
              <router-link to="/partner">合作伙伴</router-link>
            </div> -->
          </div>
        </div>
        <div class="nav-list-item">
          <div class="item-title">关于我们</div>
          <div class="item-list">
            <div class="list-li">
              <router-link
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们关于思为']);"
                to="/about"
                >关于思为</router-link
              >
            </div>
            <div class="list-li">
              <a
                href="https://ideamake.zhiye.com/home"
                onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们加入我们']);"
                target="_blank"
                >加入我们</a
              >
            </div>
            <!-- <div class="list-li">
              <router-link to="/partner">合作伙伴</router-link>
            </div> -->
          </div>
        </div>
      </div>
      <div class="pc-address">
        <span> 深圳市南山区粤海街道高新区社区科技南一路28号达实大厦35楼 @2023 深圳思为科技有限公司 版权所有 </span>
        <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow"> 粤ICP备14098888号-1 </a>
        <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" rel="nofollow"><img :src="policePng" alt="">粤公网安备44030002001306号-1</a>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="min-footer-box">
      <div class="logo">
        <img
          src="https://cdn.ideamake.cn/sw-pc/images/header_logo_white.png"
          alt="logo"
          srcset=""
        />
      </div>
      <div class="contact">
        <div class="product" @click="callPhone">
          <span class="label">产品咨询：</span>
          <span class="value phone">400-8075-618</span>
        </div>
        <div class="cooperate">
          <span class="label">市场合作：</span>
          <span class="value">marketing@ideamake.cn</span>
        </div>
      </div>
      <div class="wxQRCode">
        <div class="wxQRCode-item" v-for="item in wxQRCodeArr" :key="item.url">
          <img class="wxQRCode-img" :src="item.url" />
          <p class="wxQRCode-desc">{{ item.label }}</p>
        </div>
      </div>
      <!-- 深圳市南山区粤海街道高新区社区科技南一路28号达实智能大厦35层 -->

      <div class="address">
        <span> 深圳思为科技有限公司 版权所有 © 2022思为软件</span>
        <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow"
          >粤ICP备14098888号</a
        >
        <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" rel="nofollow">
          <img :src="policePng" alt="">粤公网安备44030002001306号-1
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
import policePng from '@/assets/img/police.png'
export default {
  name: 'SwFooter',
  data() {
    return {
      policePng,
      wxQRCodeArr: [
        {
          url: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/footer-img-01.png',
          label: '关注思为官方公众号',
        },
        {
          url: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/footer-img-02.png',
          label: '学习地产实战案例',
        },
      ],
    };
  },
  methods: {
    // 调用拨号功能
    callPhone() {
      const width = document.body.clientWidth || window.innerWidth;
      this.isMobile = width <= 980; // 如果可视区域小于980，则认为是手机设备
      if (this.isMobile) {
        return;
      }
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        '移动端底部产品咨询',
      ]);
      window.location.href = 'tel://' + '400-8075-618';
    },
  },
};
</script>
<style lang="scss" scoped>
footer {
  width: 100%;
  padding-top: 44px;
  background: #25272e;
  padding-bottom: 64px;
}
.footer-box {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  .footer-nav-list {
    display: flex;
    // padding-left: 80px;
    justify-content: space-around;
    .nav-list-item {
      .item-title {
        font-size: 16px;
        color: #fff;
      }
      .logo {
        width: 164px;
        img {
          width: 100%;
        }
      }
      .contact {
        margin-top: 28px;
        color: #e9e9e9;
        font-size: 16px;
        .value {
          margin-left: 4px;
          font-weight: 500;
        }
        .phone {
          font-size: 18px;
        }
        .cooperate {
          margin-top: 8px;
        }
      }
      .wxQRCode {
        display: flex;
        margin-top: 24px;
        color: #b4b8c0;
        font-size: 14px;
        font-weight: 400;
        &-item {
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 126px;
          flex-direction: column;
        }
        &-desc {
          margin-top: 4px;
        }
        &-img {
          width: 100px;
        }
      }

      .item-list {
        margin-top: 20px;
        font-size: 13px;
        line-height: 30px;
        color: #cfd0d6;
        &.two-item {
          width: 440px;
          display: flex;
          flex-wrap: wrap;
          .list-li {
            width: 110px;
          }
        }
        .list-li {
          opacity: 0.6;
          a {
            color: #cfd0d6;
            display: block;
            &:hover {
              color: #3777f6;
              font-weight: 500;
            }
          }
          &.img {
            opacity: 1;
            .wxQRCode-img {
              margin-top: 10px;
              width: 108px;
              height: 108px;
            }
          }
        }
      }
    }
  }
  .pc-address{
    margin-top: 30px;
    text-align: center;
    color: #a4a5a8;
    a{
      color: #a4a5a8;
    }
  }
}
.min-footer-box {
  display: none;
}
@import '@/assets/scss/min-index';
</style>
