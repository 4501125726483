import { render, staticRenderFns } from "./CustomerRecognition.vue?vue&type=template&id=07f4291e&scoped=true&"
import script from "./CustomerRecognition.vue?vue&type=script&lang=js&"
export * from "./CustomerRecognition.vue?vue&type=script&lang=js&"
import style0 from "./CustomerRecognition.vue?vue&type=style&index=0&id=07f4291e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f4291e",
  null
  
)

export default component.exports