<template>
  <div class="section-box bg-b" v-if="dataInfo">
    <h3 class="con-title">{{ title }}</h3>
    <van-sticky :container="container" :offset-top="isMobile ?  '0.62rem' : '62px' " @change="onStickyChange">
      <div class="nav-list-box" :style="isSticky ? stickyStyle : {}">
        <div class="nav-list" :style="{ width: 138 * dataInfo.length + 'px' }" v-if="!isMobile">
          <div
            v-for="(item, index) in dataInfo"
            :key="index"
            :class="['nav-list-item', { active: activeIndex === index }]"
            @click="handleClick(index, item.title)"
          >
            {{ item.title }}
          </div>
        </div>
          <div
            class="nav-list min-phone"
            :style="{ width: 1 * dataInfo.length + 'rem' }"
            v-else
          >
            <div
              v-for="(item, index) in dataInfo"
              :key="index"
              :class="['nav-list-item', { active: activeIndex === index }]"
              @click="handleClick(index, item.title)"
            >
              {{ item.title }}
            </div>
          </div>
      </div>
    </van-sticky>
    <div class="panel">
      <div
        v-for="(item, index) in dataInfo"
        :class="['panel-box', { active: item.direction === 'right' }]"
        :key="'panel' + index"
      >
        <div
          class="panel-box-con"
          :style="{
            'flex-direction': item.direction === 'right' ? 'row-reverse' : '',
          }"
        >
          <div
            class="content"
            :style="{
              'padding-left': item.direction === 'right' ? '80px' : '',
            }"
          >
            <h3 class="title">{{ item.title }}</h3>
            <p class="subtitle">{{ item.subtitle }}</p>
            <ul class="item">
              <li v-for="(text, i) in item.list" :key="'list' + i">
                {{ text }}
              </li>
            </ul>
            <div class="iamge-min-phone">
              <img loading="lazy" :src="item.image" :alt="item.title" />
            </div>
            <div class="carousel-footer">
              <div
                class="big-button"
                @click="goReservation(item.title)"
                data-agl-cvt="5"
              >
                预约演示
              </div>
              <el-popover
                placement="bottom"
                popper-class="wxQRCode-popper"
                trigger="click"
              >
                <div class="popover-box">
                  <img
                    class="wxQRCode-img"
                    src="https://cdn.ideamake.cn/sw-pc/images/sw_wx_QR_code-sider.jpeg"
                  />
                  <div class="title">添加微信</div>
                  <div class="text">专属客服将为您介绍更多产品资讯</div>
                </div>
                <div
                  class="big-button button-two"
                  @click="advice(item.title)"
                  slot="reference"
                  data-agl-cvt="5"
                >
                  立即咨询
                </div>
              </el-popover>
            </div>
          </div>
          <div class="iamge">
            <img loading="lazy" :src="item.image" :alt="item.title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import { dataList } from '../data.js';
import { swTrackerClickReport } from '@/tracker.js';
export default {
  name: 'SolutionPanel',

  data() {
    return {
      pathUrl: '',
      pathName: '',
      activeIndex: 0,
      paramsIndex: -1,
      title: '',
      dataInfo: [],
      dataList: [],
      container: null,
      isSticky: false,
      stickyOffsetTop:'62px',
      stickyStyle: {
        background: '#FDFDFD',
        boxShadow: '0px 4px 12px 0px rgba(155,165,192,0.25)',
      },
      isMobile: false,
    };
  },
  watch: {

    '$route.path'() {
      this.pathUrl = utils.changeRoutePath(this.$route.path);
      this.pathName = this.$route.name;
      this.activeIndex = this.$route.params.index || 0;
      this.getDataInfo();
      this.paramsIndex =
        this.$route.query.index === undefined ? -1 : this.$route.query.index;
      if (this.paramsIndex < 0) {
        this.scrollAnimate(0);
      } else {
        const index = Number(this.paramsIndex);
        const lis = document.querySelectorAll('.panel-box');
        this.scrollAnimate(lis[index].offsetTop - 140);
      }
    },
    '$route.query'() {
      this.paramsIndex =
        this.$route.query.index === undefined ? -1 : this.$route.query.index;
      if (this.paramsIndex < 0) {
        this.scrollAnimate(0);
      }
    },
    paramsIndex() {
      if (this.paramsIndex < 0) {
        this.scrollAnimate(0);
      } else {
        const index = Number(this.paramsIndex);
        const lis = document.querySelectorAll('.panel-box');
        this.scrollAnimate(lis[index].offsetTop - 140);
      }
    },
    activeIndex: {
      handler() {
        const scrollContainer = document.querySelector('.section-box .nav-list-box');
        // 最外层包裹住item，负责滚动，
        if (this.isSticky && this.isMobile) {
          // 单个tab
          let activeNode = document.querySelector('.nav-list-item.active');
          // 元素距离滑块左边距离  -  居中时元素到窗口左边距离  =  滚动条滑动的距离
          let scrollWidth =
            activeNode.offsetLeft -
            (document.body.clientWidth - activeNode.clientWidth) / 2;

          scrollContainer.scrollLeft = scrollWidth;
        }
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  
  
  },
  mounted() {
    setTimeout(() => {
      this.container = document.getElementById('app')
      // console.log(this.container,'this.container')
    }, 300);
   
  },
  created() {
    this.dataList = dataList;
    window.addEventListener('scroll', this.handleScroll);
  
    this.pathUrl = utils.changeRoutePath(this.$route.path);
    this.paramsIndex =
      this.$route.query.index === undefined ? -1 : this.$route.query.index;
    this.pathName = this.$route.name;
    this.getDataInfo();
    this.judgeIsMobile()
  },
  methods: {
    // 触顶触发
    onStickyChange(val) {
 
      this.isSticky = val
    },

    judgeIsMobile() { 
      const width = document.body.clientWidth || window.innerWidth
      this.isMobile = width <= 980; // 如果可视区域小于980，则认为是手机设备
    },



    // 点击tab切换
    handleClick(i, title) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `产品与解决方案${this.pathName}页主体导航栏${title}按钮`,
      ]);
      // this.activeIndex = i
      const index = Number(i);
      const lis = document.querySelectorAll('.panel-box');
      this.scrollAnimate(lis[index].offsetTop - 140);
    },
    // 滚动动画
    scrollAnimate(scrollHeight) {
      window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      // 得到页面滚动的距离，.panel-box dom有的时候没有4个
      const lis = document.querySelectorAll('.panel-box');
      const clientHeight = window.innerHeight || document.documentElement.clientHeight

      // let activeIndex = 0;
      // if (lis[1] && window.pageYOffset < lis[1].offsetTop - 140) {
      //   activeIndex = 0;
      // } else if (lis[2] && window.pageYOffset < lis[2].offsetTop - 140) {
      //   activeIndex = 1;
      // } else if (lis[3] && window.pageYOffset < lis[3].offsetTop - 140) {
      //   activeIndex = 2;
      // } else {
      //   activeIndex = lis.length <= 3 ? 2 : 3;
      // }

      const index = lis && Array.from(lis).findIndex((item, index) => {
         return window.pageYOffset - lis[index].offsetTop <  clientHeight / 3
      })
      this.activeIndex = index === -1 ? lis.length - 1 : index;

    
    },
    goReservation(pathModule) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `产品与解决方案${this.pathName}页主体${pathModule}预约演示按钮`,
      ]);
      swTrackerClickReport(
        'sw_pc_free_trial',
        pathModule,
        '解决方案-' + this.pathName
      );
      this.$router.push({ name: 'reservation', params: { type: 1 } });
    },
    advice(pathModule) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `产品与解决方案${this.pathName}页主体${pathModule}立即咨询按钮`,
      ]);
    },
    getDataInfo() {
      const currentData = this.dataList.filter(
        (item) => item.pathUrl === this.pathUrl
      )[0];
      this.title = currentData.title;
      this.dataInfo = currentData.dataInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-box {
  width: 100%;
  height: 100%;
  padding: 80px 0 10px;
  padding-bottom: 0;
  text-align: center;
  &.bg-w {
    background: #f7f9fb;
  }
  &.bg-b {
    background: #fff;
  }
  .con-title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
    padding-bottom: 48px;
  }
  .nav-list-box{
    background: #fff;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-list {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &.min-phone {
      display: none;
    }
    .nav-list-item {
      min-width: 138px;
      height: 40px;
      margin-right: 12px;
      background: rgba(255, 255, 255, 0);
      border-radius: 20px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #5a5c60;
      line-height: 40px;
      cursor: pointer;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        background: #f5f7fa;
      }
      &.active {
        background: #3777f6;
        color: #ffffff;
      }
    }
  }
}
.panel {
  width: 100%;
  &-box {
    width: 100%;
    padding: 56px 0 70px;
    padding-bottom: 56px;
    background-color: #ffffff;
    &.active {
      // background: linear-gradient(90deg, rgba(213,231,238,0.13) 0%, rgba(237,239,244,0.35) 100%);
      background: url(https://cdn.ideamake.cn/sw-pc/images/web3.0/background-photo.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      backdrop-filter: blur(50px);
    }
    .panel-box-con {
      width: 1200px;
      margin: 0 auto;
      display: flex;
    }
    .content {
      text-align: left;
      // padding-top: 40px;
      display: flex;
      flex-direction: column;
     justify-content: center;
      .title {
        font-size: 28px;
        font-weight: 600;
        color: #2d2e30;
        line-height: 40px;
        width: 425px;
      }
      .subtitle {
        margin-top: 16px;
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #2d2e30;
        width: 425px;
      }
      .item {
        margin-top: 32px;
        margin-bottom: 30px;
        width: 425px;
        li {
          position: relative;
          margin: 8px 0 0 2px;
          color: #5a5c60;
        }
        li::after {
          content: '';
          position: absolute;
          left: -16px;
          top: 6px;
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #5f92f8;
        }
      }
      .iamge-min-phone {
        display: none;
      }
      .carousel-footer {
        display: flex;
        .button-two {
          margin-left: 10px;
          background: #ffffff;
          color: #3777f6;
          border-radius: 2px;
          border: 1px solid #3777f6;
          font-weight: 400;
          &:hover {
            background: #afc9fb;
            color: #ffffff;
            border-color: #3777f6;
          }
          &:active {
            background: #afc9fb;
            color: #ffffff;
            border-color: #3777f6;
          }
        }
      }
    }
    .iamge img {
      display: inline-block;
      width: 722px;
      height: 448px;
    }
  }

  .big-button {
    width: 106px;
    height: 40px;
    background: #3777f6;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    &:hover {
      background: #5f92f8;
    }
  }
}

// 移动端布局
@import '@/views/solution/min-solution';
</style>
