<template>
  <div class="culture-page">
    <div class="left">
      <div class="left-wrapper" @click="() => handleClick(datas['value']['name'])">
        <img
          :src="datas['value']['url']"
          alt="企业价值观"
          srcset=""
          class="culture-value big-bg"
        />
        <div class="left-wrapper-body">
          <div class="top">
            <div class="top-img">
              <img loading="lazy" :src="datas['value']['logo']" alt="" srcset="" />
            </div>
            <div class="top-text">
              <span class="title">{{ datas['value']['name'] }}</span>
              <span class="desc">{{ datas['value']['lang'] }}</span>
            </div>
          </div>
          <div class="content">
            <div
              class="content-item"
              v-for="(item,index) in datas['value']['arr']"
              :class="index % 2 === 0? 'even':'odd'"
              :key="item.title"
              :style="item.style"
            >
              <span class="content-item-title">{{ item.title }}</span>
              <div class="content-item-desc">
                <span class="desc" v-for="d in item.descArr" :key="d">
                  {{ d }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-wrapper" @click="() => handleClick(datas['mission']['name'])">
        <img
          :src="datas['mission']['url']"
          alt="企业使命"
          srcset=""
          class="culture-vision  big-bg"
        />

        <div class="right-wrapper-body">
          <div class="top">
            <div class="top-img">
              <img loading="lazy" :src="datas['mission']['logo']" alt="" srcset="" />
            </div>
            <div class="top-text">
              <span class="title">{{ datas['mission']['name'] }}</span>
              <span class="desc">{{ datas['mission']['lang'] }}</span>
            </div>
          </div>
          <div class="content">
            <div
              class="content-item"
              v-for="item in datas['mission']['arr']"
              :key="item.title"
              :style="{ width: item.itemWidth }"
            >
              <span class="content-item-title">{{ item.title }}</span>
              <div class="content-item-desc">
                <span class="desc" v-for="d in item.descArr" :key="d">
                  {{ d }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right-wrapper" @click="() => handleClick(datas['vision']['name'])">
        <img
          :src="datas['vision']['url']"
          alt="品牌愿景"
          srcset=""
          class="culture-vision  big-bg"
        />
        <div class="right-wrapper-body">
          <div class="top">
            <div class="top-img">
              <img loading="lazy" :src="datas['vision']['logo']" alt="" srcset="" />
            </div>
            <div class="top-text">
              <span class="title">{{ datas['vision']['name'] }}</span>
              <span class="desc">{{ datas['vision']['lang'] }}</span>
            </div>
          </div>
          <div class="content">
            <div
              class="content-item"
              v-for="item in datas['vision']['arr']"
              :key="item.title"
              :style="{ width: item.itemWidth }"
            >
              <span class="content-item-title">{{ item.title }}</span>
              <div class="content-item-desc">
                <span class="desc" v-for="d in item.descArr" :key="d">
                  {{ d }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutCulture',

  data() {
    return {
      activeName: 'value',
      datas: {
        value: {
          name: '企业价值观',
          url: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-culture-01.png',
          logo: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-culture-logo-01.png',
          lang: 'Corporate values',
          arr: [
            {
              title: '客户第一',
              descArr: ['为客户创造真实价值', '不辜负每一份信任'],

              style: {
                width: '40%',
               
              },
            },

            {
              title: '主人翁精神',
              descArr: [
                ' 独立思考不唯上',
                '因为信任，所以简单',
                '目标一致，相互帮助，共同达成',
              ],

              style: {
                width: '45%',
              
              },
            },
            {
              title: '追求卓越',

              style: {
                width: '40%',
                marginTop: '0px',
              },
              descArr: ['极致专注', '做难而正确的事情', 'the best or nothing'],
            },
            {
              title: '求真务实',
              descArr: [
                '讲真话，干实事',
                '先做60分，再做100分',
                '只为成功找方法，不为失败找理由',
              ],

              style: {
                width: '45%',
             
              },
            },
            {
              title: '开放谦逊',
              descArr: ['开卷考试', 'Stay hungry Stay foolish'],

              style: {
                width: '40%',
                marginTop: '2px',
              },
            },
            {
              title: '坦诚清晰',
              descArr: ['直面冲突、坦诚沟通、解决分歧'],
              style: {
                width: '45%',
               
              },
            },
          ],
        },
        mission: {
          name: '企业使命',
          url: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-culture-03.png',
          logo: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-culture-logo-03.png',
          lang: 'Corporate Mission',
          arr: [
            {
              title: '做最好的营销CRM',
              descArr: [],
              itemWidth: '100%',
            },
          ],
        },

        vision: {
          name: '品牌愿景',
          url: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-culture-02.png',
          logo: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/about-culture-logo-02.png',
          lang: 'Corporate Vision',
          arr: [
            {
              title: '成为世界级的软件服务企业',
              descArr: [],
              itemWidth: '100%',
            },
          ],
        },
      },
    };
  },
  methods: {
    handleClick(name) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `关于我们关于思为企业文化${
          name
        }主体tab`,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.culture-page {
  width: 100%;
  height: 100%;
  margin-top: 60px;
  display: flex;
  .left {
    width: 632px;
    margin-right: 16px;
    &-wrapper {
      position: relative;
      background: #fff;
      z-index: 1;
      &-body {
        padding: 24px;
      }
    }
  }
  .culture-value {
    width: 100%;
    position: absolute;
    z-index: -1;
  }
  .right {
    width: 552px;
    &-wrapper {
      position: relative;
      z-index: 1;
      height: 250px;
      &-body {
        padding: 24px;
      }
      &:first-child {
        margin-bottom: 24px;
      }
      .culture-vision {
        height: 100%;
        position: absolute;
        // right: -10px;
        z-index: -1;
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    &-img {
      height: 64px;
      width: 64px;
      margin-right: 16px;
      img {
        width: 100%;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      height: 64px;
      justify-content: space-around;
      .title {
        font-weight: 500;
        color: #2d2e30;
        font-size: 24px;
      }
      .desc {
        color: #5a5c60;
        font-size: 16px;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: 50%;
      margin-top: 24px;
      &:nth-child(1) {
        margin-top: 44px;
      }
      &:nth-child(2) {
        margin-top: 44px;
      }
      &-title {
        font-size: 18px;
        font-weight: 500;
        color: #2d2e30;
      }
      &-desc {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        color: #5a5c60;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

// 移动端布局
@import '@/views/about/min-about';
</style>
