<template>
  <div
    class="customer-recognition"
    
  >
  <!-- :style="{ 'background-color': pathUrl === '/' ? '#F7F9FB' : '' }" -->
    <div class="recognition-content">
      <div class="recognition-title">客户的认可 是品牌最好的背书</div>
      <!-- <el-tabs v-model="activeName"  type="card" @tab-click="handleClick" class="el-item1">
                <el-tab-pane name="first">
                    <span slot="label">
                        <img class="first-img-type" src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-header-one.png" alt="">
                    </span>
                    <div class="first-one">
                        <div class="first-content">
                            <div class="content-left">
                            <div class="content-img">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-white-title-one.png" alt="">
                            </div>
                            <div class="content-word">
                                    <span class="content-word-content">
                                        深耕地产行业11年，基于领先的产品矩阵和强大的咨询服务能力，向开发商提供以营销流程一体化打通、客户资产沉淀及运营为核心的全场景营销解决方案，陪伴优秀开发商实现从业务到管理的转型升级
                                    </span>
                            </div>
                            <div class="content-name">
                                    <div class="content-name-icon">——</div>
                                    <div>
                                        <div class="content-name-type-name">王文中</div>
                                        <div class="content-name-type-type">招商蛇口</div>
                                    </div>
                            </div>
                            </div>
                            <div class="content-right">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-upgrade-background.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="second">
                    <span slot="label">
                        <img class="first-img-type" src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-header-two.png" alt="">
                    </span>
                    <div class="first-one">
                        <div class="first-content">
                            <div class="content-left">
                            <div class="content-img">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-white-title-one.png" alt="">
                            </div>
                            <div class="content-word">
                                    <span class="content-word-content">
                                        深耕地产行业11年，基于领先的产品矩阵和强大的咨询服务能力，向开发商提供以营销流程一体化打通、客户资产沉淀及运营为核心的全场景营销解决方案，陪伴优秀开发商实现从业务到管理的转型升级
                                    </span>
                            </div>
                            <div class="content-name">
                                    <div class="content-name-icon">——</div>
                                    <div>
                                        <div class="content-name-type-name">王文中</div>
                                        <div class="content-name-type-type">招商蛇口</div>
                                    </div>
                            </div>
                            </div>
                            <div class="content-right">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-upgrade-background.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="third">
                    <span slot="label">
                        <img class="first-img-type" src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-header-three.png" alt="">
                    </span>
                    <div class="first-one">
                        <div class="first-content">
                            <div class="content-left">
                            <div class="content-img">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-white-title-one.png" alt="">
                            </div>
                            <div class="content-word">
                                    <span class="content-word-content">
                                        深耕地产行业11年，基于领先的产品矩阵和强大的咨询服务能力，向开发商提供以营销流程一体化打通、客户资产沉淀及运营为核心的全场景营销解决方案，陪伴优秀开发商实现从业务到管理的转型升级
                                    </span>
                            </div>
                            <div class="content-name">
                                    <div class="content-name-icon">——</div>
                                    <div>
                                        <div class="content-name-type-name">王文中</div>
                                        <div class="content-name-type-type">招商蛇口</div>
                                    </div>
                            </div>
                            </div>
                            <div class="content-right">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-upgrade-background.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="fourth">
                    <span slot="label">
                        <img class="first-img-type" src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-header-four.png" alt="">
                    </span>
                    <div class="first-one">
                        <div class="first-content">
                            <div class="content-left">
                            <div class="content-img">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-white-title-one.png" alt="">
                            </div>
                            <div class="content-word">
                                    <span class="content-word-content">
                                        深耕地产行业11年，基于领先的产品矩阵和强大的咨询服务能力，向开发商提供以营销流程一体化打通、客户资产沉淀及运营为核心的全场景营销解决方案，陪伴优秀开发商实现从业务到管理的转型升级
                                    </span>
                            </div>
                            <div class="content-name">
                                    <div class="content-name-icon">——</div>
                                    <div>
                                        <div class="content-name-type-name">王文中</div>
                                        <div class="content-name-type-type">招商蛇口</div>
                                    </div>
                            </div>
                            </div>
                            <div class="content-right">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-upgrade-background.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="five">
                    <span slot="label">
                        <img class="first-img-type" src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-header-five.png" alt="">
                    </span>
                    <div class="first-one">
                        <div class="first-content">
                            <div class="content-left">
                            <div class="content-img">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-white-title-one.png" alt="">
                            </div>
                            <div class="content-word">
                                    <span class="content-word-content">
                                        深耕地产行业11年，基于领先的产品矩阵和强大的咨询服务能力，向开发商提供以营销流程一体化打通、客户资产沉淀及运营为核心的全场景营销解决方案，陪伴优秀开发商实现从业务到管理的转型升级
                                    </span>
                            </div>
                            <div class="content-name">
                                    <div class="content-name-icon">——</div>
                                    <div>
                                        <div class="content-name-type-name">王文中</div>
                                        <div class="content-name-type-type">招商蛇口</div>
                                    </div>
                            </div>
                            </div>
                            <div class="content-right">
                                <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-upgrade-background.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs> -->
    </div>
    <div class="recognition-content2">
      <!-- 第一行 -->
      <div class="content2-item">
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-1.png"
            alt="招商蛇口"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-2.png"
            alt="越秀地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-3.png"
            alt="中海地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-1.png"
            alt="建发房产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-2.png"
            alt="保利发展控股"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-10.png"
            alt="华发集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-6.png"
            alt="华润置地"
          />
        </div>
      </div>
      <!-- 第二行 -->
      <div class="content2-item">
        <div class="first-img-type2">
          <img
            class="first-img-type-img first-img-type-img-change"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-8.png"
            alt="北科建"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-7.png"
            alt="融创"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-3.png"
            alt="象屿地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-13.png"
            alt="万科"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-35.png"
            alt="人才安居"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-36.png"
            alt="龙湖"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img first-img-type-img-change"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-11.png"
            alt="金地集团"
          />
        </div>
      </div>
      <!-- 第三行 -->
      <div class="content2-item">
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-37.png"
            alt="联发"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-7.png"
            alt="国贸地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-38.png"
            alt="广州城投"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-39.png"
            alt="广州地铁"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-5.png"
            alt="北京金隅集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-31.png"
            alt="光大地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-28.png"
            alt="德信地产"
          />
        </div>
      </div>
      <!-- 第四行 -->
      <div class="content2-item">
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-34.png"
            alt="绿城中国"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-40.png"
            alt="绿地控股"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-29.png"
            alt="华中集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-12.png"
            alt="华侨城地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-16.png"
            alt="中国铁建"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-41.png"
            alt="北京建工"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img first-img-type-img-change"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-15.png"
            alt="卓越集团"
          />
        </div>
      </div>
      <!-- 第五行 -->
      <div class="content2-item">
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-18.png"
            alt="香港置地"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-42.png"
            alt="新鸿基地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-43.png"
            alt="立标集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-44.png"
            alt="隆恩"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-45.png"
            alt="中旅地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-46.png"
            alt="满京华集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-47.png"
            alt="美的置业"
          />
        </div>
      </div>
      <!-- 第六行 -->
      <div
        class="content2-item"
        :style="[
          { position: pathUrl === '/' ? 'relative' : '' },
          { 'z-index': pathUrl === '/' ? 1 : 0 },
        ]"
      >
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-48.png"
            alt="旅文三亚总部港"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-49.png"
            alt="卓尔集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-50.png"
            alt="思锐旅居"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-51.png"
            alt="格力地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-52.png"
            alt="龙岩市土地发展公司"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-53.png"
            alt="路劲地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-24.png"
            alt="金科"
          />
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="recognition-content2 min-recognition">
      <!-- 第一行 -->
      <div class="content2-item min-content">
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-1.png"
            alt="招商蛇口"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-2.png"
            alt="越秀地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-3.png"
            alt="中海地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-1.png"
            alt="建发房产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-2.png"
            alt="保利发展控股"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-10.png"
            alt="华发集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-6.png"
            alt="华润置地"
          />
        </div>

    
        <div class="first-img-type2">
          <img
            class="first-img-type-img first-img-type-img-change"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-8.png"
            alt="北科建"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-7.png"
            alt="融创"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-3.png"
            alt="象屿地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-13.png"
            alt="万科"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-35.png"
            alt="人才安居"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-36.png"
            alt="龙湖"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img first-img-type-img-change"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-11.png"
            alt="金地集团"
          />
        </div>


        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-37.png"
            alt="联发"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-7.png"
            alt="国贸地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-38.png"
            alt="广州城投"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-39.png"
            alt="广州地铁"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-log-new-5.png"
            alt="北京金隅集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-31.png"
            alt="光大地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-28.png"
            alt="德信地产"
          />
        </div>

        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-34.png"
            alt="绿城中国"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-40.png"
            alt="绿地控股"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-29.png"
            alt="华中集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-12.png"
            alt="华侨城地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-16.png"
            alt="中国铁建"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-41.png"
            alt="北京建工"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img first-img-type-img-change"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-15.png"
            alt="卓越集团"
          />
        </div>


        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-18.png"
            alt="香港置地"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-42.png"
            alt="新鸿基地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-43.png"
            alt="立标集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-44.png"
            alt="隆恩"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-45.png"
            alt="中旅地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-46.png"
            alt="满京华集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-47.png"
            alt="美的置业"
          />
        </div>

        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-48.png"
            alt="旅文三亚总部港"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-49.png"
            alt="卓尔集团"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-50.png"
            alt="思锐旅居"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-51.png"
            alt="格力地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-52.png"
            alt="龙岩市土地发展公司"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-53.png"
            alt="路劲地产"
          />
        </div>
        <div class="first-img-type2">
          <img
            class="first-img-type-img"
            src="https://cdn.ideamake.cn/sw-pc/images/customer-logo-24.png"
            alt="金科"
          />
        </div>
      </div>
    </div>
    <div v-if="pathUrl === '/'" class="customer-recognition-footer2">

      <img
        class="image"
        src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-footer.png"
        alt=""
      />
     
      <img
        class="min-image"
        src="https://cdn.ideamake.cn/sw-pc/images/customer-recognition-footer-min.png"
        alt=""
      />
    </div>
    <div v-else class="customer-recognition-footer"></div>
  </div>
</template>
<script>
import utils from '@/utils';
export default {
  name: 'customer-recognition',
  props: {},
  data() {
    return {
      activeName: 'first',
      pathUrl: '',
    };
  },
  watch: {
    '$route.path'() {
      this.pathUrl = utils.changeRoutePath(this.$route.path);
      // 刷新组件防止选中BUG
      //     if(this.pathUrl === '/experience'){

      //         } else if (this.pathUrl === '/reservation'){

      //     }
    },
  },
  created() {
    this.pathUrl = utils.changeRoutePath(this.$route.path);
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-recognition {
  position: relative;
  .recognition-content {
    margin: 0 auto;
    width: 1200px;
    padding: 70px 0 0;
    // height: 718px;
    .first-img-type {
      width: auto;
      height: auto;
      max-width: 60%;
      max-height: 60%;
      margin-left: 10px;
      -webkit-filter: grayscale(1); /* Webkit */
      filter: gray; /* IE6-9 */
      filter: grayscale(1); /* W3C */
      opacity: 0.5;
    }
    .first-one {
      height: 390px;
      .first-content {
        height: 350px;
        background-color: #3756e2;
        display: flex;
        justify-content: space-between;
        position: relative;
        .content-left {
          padding: 32px;
          .content-img img {
            display: inline-block;
            width: 128px;
            height: 32px;
            margin-bottom: 35px;
          }
          .content-word {
            width: 456px;
            height: 152px;
            background: url('https://cdn.ideamake.cn/sw-pc/images/customer-recognition-background.png');
            background-size: cover;
            .content-word-content {
              display: inline-block;
              width: 418px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              padding: 31px 0 0 8px;
            }
          }
          .content-name {
            color: #ffffff;
            display: flex;
            .content-name-icon {
              font-size: 16px;
              font-weight: 400;
              width: 32px;
              margin-top: -1px;
            }
            .content-name-type-name {
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 5px;
            }
            .content-name-type-type {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
        .content-right {
          position: absolute;
          right: 40px;
          top: 40px;
        }
        .content-right img {
          width: 566px;
          height: 350px;
          opacity: 0;
        }
      }
    }
    ::v-deep .el-tabs {
      .el-tabs__header {
        display: flex;
        justify-content: center;
        margin: 0px 0 55px;
        border: none;
        .el-tabs__nav {
          display: flex;
          align-items: flex-end;
        }
      }
      .el-tabs__item {
        width: 219px;
        height: 80px;
        text-align: center;
        background: linear-gradient(180deg, #f3f5f7 0%, #ffffff 100%);
        border: 1.5px solid #ffffff;
        box-shadow: 0px 4px 12px 0px rgba(155, 165, 192, 0.25),
          inset 0px 1px 3px 0px rgba(0, 0, 0, 0.03);
        line-height: 80px;
        padding: 0;
        // margin-right: 24px;
        &:hover img {
          -webkit-filter: grayscale(0); /* Webkit */
          filter: none; /* IE6-9 */
          filter: grayscale(0); /* W3C */
          opacity: 1;
        }
      }
      .el-tabs__item:not(:last-child) {
        margin-right: 24px;
      }
      .el-tabs__item:last-child {
        margin-right: 5px;
      }
      .el-tabs__nav-scroll {
        overflow: initial;
        .el-tabs__nav {
          padding-bottom: 25px;
          border: none;
          .el-tabs__active-bar {
            background-color: white;
          }
          .is-active img {
            -webkit-filter: grayscale(0); /* Webkit */
            filter: none; /* IE6-9 */
            filter: grayscale(0); /* W3C */
            opacity: 1;
          }
        }
      }
      .el-tabs__nav-wrap {
        padding-top: 12px;
      }

      .el-tabs__nav-wrap::after {
        background-color: white;
      }
      .is-active {
        height: 88px !important;
      }
      .is-active::after {
        content: url(https://cdn.ideamake.cn/sw-pc/images/type.png);
        display: inline-block;
        // background: url('https://xfjoss.coli688.com/pc-images/play-icon.png');
        // background-size: cover;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0px;
        top: -30px;
      }
      .is-active::before {
        content: '';
        height: 3px;
        width: 219px;
        background: #3777f6;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .recognition-title {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2d2e30;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .recognition-content2 {
    margin: 0 auto;
    width: 1250px;
    padding: 40px 0 100px;
    height: 600px;
    // margin-bottom: 100px;
    .content2-item {
      display: flex;
     // justify-content: space-between;
      // flex-wrap:wrap;
      justify-content: flex-start;
      margin-bottom: 15.5px;
    }
    .content2-item2 {
      display: flex;
      .first-img-type2 {
        margin-right: 15.5px;
      }
    }
    .first-img-type2 {
      width: 165px;
      height: 66px;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      margin-right: auto;
      text-align: center;
      position: relative;
      &:last-of-type {
        margin-right: 0;
      }
      .first-img-type-img {
        width: auto;
        height: auto;
        max-width: 70%;
        max-height: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .first-img-type-img-change {
        max-width: 100%;
        max-height: 100%;
      }
      .first-img-type-img-middle {
        max-width: 80%;
        max-height: 80%;
      }
    }
  }
  .min-recognition {
    display: none;
  }
  .customer-recognition-footer {
    background: url('https://cdn.ideamake.cn/sw-pc/images/customer-recognition-footer.png');
    background-size: 1512px 133px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 0px;
    width: 100%;
    min-width: 1200px;
    height: 133px;
    z-index: -1;
  }
  .min-content {
    z-index: 1;
    position: relative;
  }
  .customer-recognition-footer2 {
    .min-image {
      display: none;
    }
    img {
      width: 1512px;
      height: 133px;
    }
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
    width: 100%;
    min-width: 1200px;
    height: 133px;
    z-index: 0;
  }
}

// 移动端布局
@import '@/views/home/min-home';
</style>
