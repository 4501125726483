<template>
  <div class="consult-dialog">
    <div class="dialog-title" slot="title">
      <div class="title-text title">
        提交申请，即刻开启营销数字化升级
      </div>
      <div class="title-text">
        思为专业服务人员将在1个工作日内尽快与您联系，或可拨打
        <span>400-8075-618 </span>与我们交流
      </div>
    </div>
    <div id="DOMid" class="form-con">
      <el-form ref="form" label-position="right" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="姓名" prop="customerName">
          <el-input v-model="form.customerName"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="customerPhone">
          <el-input maxlength="11" v-model="form.customerPhone"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="corpName">
          <el-input maxlength="22" v-model="form.corpName"></el-input>
        </el-form-item>
        <el-form-item label="需求备注">
          <el-input type="textarea" maxlength="200" :autosize="{ minRows: 4, maxRows: 6}" show-word-limit v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="sub-btn" @click="submitForm">提交申请</div>
    </span>
    <el-dialog
      :visible.sync="qwVisible"
      width="360px"
      append-to-body
      custom-class="consult-dialog qw"
      :show-close="false"
      :close-on-click-modal="false"
      center
    >
      <div class="dialog-title qw" slot="title">
        <div class="title-text">
          信息提交成功，扫码添加 <br> 企业微信，立即了解更多产品详情。
        </div>
        <div class="title-text phone-min">
          信息提交成功
        </div>
        <div class="title-text-btn phone-min">
          立即了解更多产品详情
        </div>
        <div class="close" @click="handleQwClose">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="qw-con">
        <div class="qw-con-img pc">
          <el-image
          style="width: 300px; height: 300px"
          :src="qr_code"
          fit="fill"></el-image>
        </div>
        <div class="qw-con-img phone-min">
          <div class="img-box" :style="{ 'background-image': `url(${qr_code})` }"></div>
          <!-- <div
            class="zhezhao"
            @touchstart="handlerTouchstart"
            @touchmove="handlerTouchmove"
            @touchend="handlerTouchend"
          ></div> -->
        </div>
        <div class="title-text phone-min">
          保存二维码，微信扫码了解更多产品资讯
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import md5 from "js-md5"
let Base64 = require('js-base64').Base64

export default {
  name: 'ConsultDialog',
  data () {
    return {
      consultVisible: false,
      loading: false,
      form: {
        corpName: '',
        customerName: '',
        customerPhone: '',
        remark: '',
      },
      rules: {
        customerName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '请输入最少 2 个字符', trigger: 'blur' }
        ],
        customerPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ],
        corpName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 2, max: 30, message: '请输入最少 2 个字符', trigger: 'blur' }
        ],
      },
      qr_code: 'https://cdn.ideamake.cn/sw-pc/images/sw_wx_QR_code-sider.jpeg', // 企业微信
      qwVisible: false
    }
  },
  methods: {
    init() {
      this.consultVisible = true
      this.$nextTick(()=>{
        this.$refs['form'].resetFields()
      })
    },
    handleClose(){
      this.consultVisible = false
      this.$store.commit('NotStayConsultation')
    },
    // 提交表单
    submitForm() {
      window._hmt.push(['_trackEvent', 'button','click', '预约交流与产品演示留资表单提交申请按钮']);
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const timestamp = Date.now()
          const params = {
            ... this.form,
            timestamp
          }
          const paramsJSON = JSON.stringify(params)
          const salt='SBsBFh7tniuli0dm'
          const sig = Base64.encode(md5(paramsJSON + salt))  
          this.$api.submitCustomerForm(sig ,params).then(res => {
            this.loading = false
            if (res.code === 200) {
              // 百度营销接入
              window._agl && window._agl.push(['track', ['success', {t: 3}]])
              this.setQW()
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
        }
      })
    },
    // 设置获取企业微信数据
    setQW () {
      this.form = {
        corpName: '',
        customerName: '',
        customerPhone: '',
        remark: '',
      },
      this.qwVisible = true
    },
    handleQwClose(){
      this.qwVisible = false
      this.handleClose()
    },

    handlerTouchstart () {
      this.loop = setTimeout(() => {
        this.loop = 0
        // 执行长按要执行的内容
        this.goQw()
      }, 500) // 定时的时间
      return false
    },
    handlerTouchmove () {
      // 清除定时器
      clearTimeout(this.loop)
      this.loop = 0
    },
    handlerTouchend () {
      // 清除定时器
      clearTimeout(this.loop)
      if (this.loop !== 0) {
        // 单击操作
        // console.log(222)
        this.goQw()
      }
    }
  }
}   
</script>
<style lang="scss">
.consult-dialog{
  width: 618px;
  height: 558px;
  margin-top: 70px;
  box-shadow: 0px 4px 12px 0px rgba(155,165,192,0.25);
  border-radius: 6px;
  position: relative;
    .dialog-title{
      line-height: 1.8;
      background: url(https://cdn.ideamake.cn/sw-pc/images/form-banner.png);
      background-size: 100% 142px;
      background-position: 50%;
      background-size: cover;
      padding: 30px 28px;
      .title-text{
        font-size: 14px;
        font-weight: 400;
        color: #5A5C60;
        span{
          color: #3777F6;
          font-size: 16px;
        }
      }
      .title{
        font-size: 16px;
        font-weight: 500;
        color: #2D2E30;
      }
      .close{
        position: absolute;
        cursor: pointer;
        right: -38px;
        top: -38px;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        i{
          position: absolute;
          left: 14px;
          bottom: 14px;
          font-size: 20px;
        }
      }
      &.qw{
        margin-top: 16px;
        .title-text{
          font-size: 16px;
          &.phone-min{
            display: none;
          }
        }
        .title-text-btn{
          &.phone-min{
            display: none;
          }
        }
      }
  }
  .form-con {
    padding: 50px 70px 20px;
  }
  .qw-con{
    padding-bottom: 15px;
    .title-text {
      &.phone-min {
        display: none;
      }
    }
    .qw-con-img{
      &.phone-min {
        display: none;
      }
    }
  }
  .dialog-footer{
    .sub-btn{
      width: 396px;
      height: 40px;
      line-height: 40px;
      margin-left: 25%;
      border-radius: 2px;
      background: #3777F6;
      cursor: pointer;
      font-size: 13px;
      color: #fff;
      text-align: center;
      &:hover{
        background: #5F92F8;
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .consult-dialog{
    width: 100%;
    height: 5.35rem;
    margin-top: 0.30rem;
    .dialog-title{
      padding: 0.30rem 0.15rem;
      background: url(https://cdn.ideamake.cn/sw-pc/images/min-form-banner.png);
      background-size: cover;
      width: 100%;
      height: 1.28rem;
    }
    .form-con{
      padding: 0.27rem 0.24rem 0.20rem
    }
    .sub-btn{
      width: 2.31rem !important;
      margin-left: 29% !important;
    }
  }
}
@import "@/assets/scss/min-index";
</style>