import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookie from 'vue-cookie'
import api from '@/api'
import Vant from 'vant';
import Element from 'element-ui';

import { Message } from 'element-ui'

import dayjs from "dayjs"
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';

import tracker from './tracker'
tracker.install(Vue)

Vue.config.productionTip = false;

// 页面全局样式
import "@/assets/scss/style.scss";
import * as VueJsonp from 'vue-jsonp'
Vue.use(VueJsonp)
Vue.config.productionTip = false;
Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.use(VueCookie)
Vue.use(Vant)
// 挂载全局
Vue.prototype.$api= api // ajax请求方法
Vue.prototype.$day = dayjs;//可以全局使用dayjs
Vue.prototype.$message = Message
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount("#app");
