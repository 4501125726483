import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stayConsultation: false
  },
  getters: {
  },
  mutations: {
    doStayConsultation(state){
      state.stayConsultation = true;
    },
    NotStayConsultation(state){
      state.stayConsultation = false;
    },
  },
  actions: {
  },
  modules: {},
})
