<template>
  <div ref="menuConent" class="menuConent">
    <div class="home-con home-con-big">
      <!-- 首页主体tab内容 -->
      <div class="target-div">
        <div
          class="home-con-wrapper"
          v-for="(item, index) in list"
          :key="index"
          :class="[{ active: index % 2 === 1 }]"
        >
          <div class="home-con-item home-con-item-big">
            <div v-if="(index + 1) % 2 === 0" class="tab-img-box">
              <img loading="lazy" class="first-img" :src="item.imgSrc + '?x-oss-process=image/resize,m_fill,w_722,h_448'" alt="" />
            </div>
            <div class="first-word">
              <div class="first-word-big">
                {{ item.title }}
              </div>
              <div class="first-word-middle">{{ item.content }}</div>
              <div class="home-icon-list-box">
                <div
                  v-for="(subItem, subIndex) in item.list"
                  :key="subIndex"
                  class="list"
                >
                  <i class="list-icon el-icon-success"></i>
                  <div class="list-wrap">
                    <h3 class="list-title">{{ subItem.title }}</h3>
                    <div class="list-content">{{ subItem.content }}</div>
                  </div>
                </div>
              </div>
              <div class="button-bottom">
                <div class="button-bottom-title" :class="{'button-bottom-title-jump': ['crm', 'digitalSandTable'].includes(item.name)}" @click="goSolution(item)">
                  <img loading="lazy" class="button-bottom-title-icon" :src="item.icon" alt="">
                  {{ item.title }}
                </div>
                <div class="button-bottom-detail-wrap">
                  <div
                    v-for="(route, routeIndex) in item.routeList" :key="routeIndex"
                    class="button-bottom-detail"
                    :class="{'min-button-top': index === list.length - 1 && routeIndex === 2}"
                    @click="goSolutionDetail(route)"
                    data-agl-cvt="5"
                  >
                    <img loading="lazy" class="button-bottom-detail-icon" :src="route.icon" :alt="route.title">
                    {{ route.title }}
                  </div>
                </div>
              </div>
              <div class="min-phone-tab-img-box">
                <img loading="lazy" class="first-img" :src="item.imgSrc" alt="" />
              </div>
            </div>
            <div v-if="(index + 1) % 2 !== 0" class="tab-img-box">
              <img loading="lazy" class="first-img" :src="item.imgSrc + '?x-oss-process=image/resize,m_fill,w_722,h_448'" alt="" />
            </div>
          </div>
          <div
              v-if="index === list.length - 1"
              class="free-button" 
              @click="information('客户获取-线上展厅')"
              data-agl-cvt="5"
            >
              免费试用
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swTrackerClickReport } from '@/tracker.js';
import { solutionList } from '@/components/constant.js'

export default {
  name: 'menu-Conent',
  props: {},
  data() {
    return {
      list: [
        {
          imgSrc: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-crm.png',
          title: '销冠CRM',
          name: 'crm',
          list: [
            { title: '公私域融合', content: '打通公私域流量壁垒，公域快速引流+私域运营合并发力' },
            { title: '线索运营', content: '智能分客-洗客-识客-跟客，助力挖需提升后链路转化率' },
            { title: '协作提效', content: '拉通多角色分工协作，全方位经营数据分析，轻松化管理团队' },
            { title: '成长激励', content: '释放更充分的激励机制，赋能销售新人快速成长' },
          ],
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-crm-icon.png',
          routeList: [],
          content: '赋能超级个体，成就百万销冠',
        },
        {
          imgSrc: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-smart.png',
          title: '智慧案场',
          name: 'smartField',
          list: [
            { title: '高效渠道管理', content: '强化房企渠道管控能力，赋能全渠道高效运转' },
            { title: '智慧案场', content: '高效规范案场服务及销售过程，实现案场数字化升级' },
            { title: '智慧交易', content: '低成本、轻量化搭建的交易管理系统，助力交易效率快速提升' },
          ],
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-smart-icon.png',
          routeList: [],
          content: '营销一体化数字案场解决方案',
        },
        {
          imgSrc: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-market.png',
          title: '营销云',
          name: 'marketCloud',
          list: [
            { title: '渠道管理', content: '强化房企自渠自拓、经纪人管控能力，赋能全渠道高效运转' },
            { title: '案场管理', content: '标准化销售过程管理及精细化客户管理，实现案场数字化升级' },
            { title: '智能工牌', content: '提升案场成交能力，结合GPT实现智能盘客，转化管理最优解' },
            { title: '渠道风控', content: '销售全过程自动化风险记录，规范案场渠道管理' },
            { title: '智慧交易', content: '低成本、轻量化搭建的交易管理系统，助力交易效率快速提升' },
          ],
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-market-icon.png',
          routeList: [],
          content: '赋能数字营销，更赋能数字管理',
        },
        {
          imgSrc: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-sand.png',
          title: '数字沙盘',
          name: 'digitalSandTable',
          list: [
            { title: 'UE5大屏', content: '三维交互仿真场景，带给用户超越真实的场景体验' },
            { title: 'iPad移动售楼系统', content: '随时随地沉浸式看房、选房、游览社区环境体' },
            { title: '微沙盘', content: '去实体化场景营销，传播内容数字化，获客信息数据化' },
            { title: '云创编辑器', content: '可视化开发平台，海量优秀案例模板，支持快速集成' },
          ],
          icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/home-sand-icon.png',
          routeList: [],
          content: '楼盘方寸间，看房零局限',
        },
      ],
      isMobile: false,
    };
  },
  created () {
    this.list.forEach(item => {
      if (item.name === 'digitalSandTable') {
        const digitalSandTable = solutionList.find(subItem => subItem.parentName === item.name)
        item.routeList = digitalSandTable.content.map((subItem, subIndex) => {
          return {
            ...subItem,
            title: subItem.text,
            code: digitalSandTable.code + `?index=${subIndex}`
          }
        })
      } else {
        item.routeList = solutionList.filter(subItem => subItem.parentName === item.name)
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.judgeIsMobile();
    });
  },
  methods: {
    judgeIsMobile() {
      const width = document.body.clientWidth || window.innerWidth;
      this.isMobile = width <= 980; // 如果可视区域小于980，则认为是手机设备
    },
    information(pathModule) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `首页主体免费试用按钮`,
      ]);
      swTrackerClickReport('sw_pc_free_trial', pathModule, '思为科技首页');
      this.$store.commit('doStayConsultation');
      this.$router.push({ name: 'reservation', params: { type: 1 } });
    },
    // 跳转解决方案对应详情页
    goSolutionDetail(route) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `首页主体${route.title}`,
      ]);
      this.$router.push({ path: route.code });
    },
    goSolution (item) {
      if (['crm', 'digitalSandTable'].includes(item.name)) {
        window._hmt.push([
          '_trackEvent',
          'button',
          'click',
          `首页主体${item.title}`,
        ]);
        this.$router.push({ path: item.name === 'crm' ? '/solution/crm' : '/solution/visual-display' });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.menuConent {
  background-color: #fff;
  .home-con {
    padding: 0 0 100px;
    padding-bottom: 0;
    height: 718px;
    .home-con-item {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .min-phone-tab-img-box {
        display: none;
      }
      .first-word {
        &:nth-child(even) {
          margin-left: 28px;
        }
        .first-word-big {
          color: #2d2e30;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }
      .first-img {
        width: 722px;
        height: 448px;
      }
    }
    &-wrapper {
      overflow: auto;
      .free-button {
        width: 202px;
        height: 48px;
        background: #3777F6;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px;
        margin: 27px auto 80px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background: #5f92f8;
        }
      }
      &.active {
        background: #f5f7fa;
      }
    }
  }
  .home-con-big {
    height: 100%;
    .home-con-item-big {
      padding: 80px 0;
    }
    .home-con-item-middle {
      padding-top: 40px;
    }
    .first-word-middle {
      font-size: 16px;
      font-weight: 400;
      color: #5a5c60;
      line-height: 26px;
      width: 425px;
      padding-bottom: 24px;
    }
    .home-icon-list-box {
      padding-bottom: 6px;
      border-bottom: 1px solid #E7EAF0;
      width: 426px;
    }
    .list {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      &-wrap {
        margin-left: 9px;
      }
      &-icon {
        color: #3777F6;
        font-size: 24px;
      }
      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #2D2E30;
        margin-bottom: 4px;
      }
      &-content {
        font-size: 14px;
        font-weight: 400;
        color: #5A5C60;
      }
    }
    .button-bottom {
      margin-top: 24px;
      &-title {
        font-size: 14px;
        font-weight: 500;
        color: #2D2E30;
        line-height: 22px;
        margin-bottom: 11px;
        &-jump {
          cursor: pointer;
          &:hover {
            color: #3777F6;
          }
        }
        &-icon {
          width: 22px;
          height: 22px;
        }
      }
      &-detail {
        display: flex;
        padding: 8px 12px;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #2D2E30;
        line-height: auto;
        background-color: rgba($color: #3777F6, $alpha: 0.1);
        border-radius: 2px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          color: #3777F6;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &-wrap {
          display: flex;
          flex-wrap: wrap;
          width: 500px;
        }
        &-icon {
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 8px;
        }
      }
    }
  }
}

// 移动端布局
@import '../min-home';
</style>
