<template>
  <div class="solution">
    <!-- 页面顶部banner -->
    <solution-banner />
    <!-- crm 企业面临 -->
    <company-face v-if="isCrmPage"></company-face>
    <!-- 页面主体内容 -->
    <solution-panel v-if="!isCrmPage" />
    <crm-panel v-else />
     <!-- 客户公司 -->
    <!-- <customer-recognition></customer-recognition> -->
    <!-- crm 角色支持 -->
    <role-support v-if="isCrmPage"></role-support>
     <!-- 市场占比 -->
    <digital-marketing></digital-marketing>

  </div>
</template>

<script>
import SolutionBanner from "./components/Banner.vue"
import SolutionPanel from "./components/Panel.vue";
import CrmPanel from "./components/CrmPanel.vue";
// import customerRecognition from '@/components/CustomerRecognition.vue'
import digitalMarketing from '@/components/DigitalMarketing.vue'
import CompanyFace from "./components/CompanyFace.vue";
import RoleSupport from "./components/RoleSupport.vue";
import swTracker from '@/mixins/tracker.js'
import utils from '@/utils'

export default {
  name: "SolutionView",
  mixins: [swTracker],
  components:{
    SolutionBanner,
    SolutionPanel,
    // customerRecognition,
    digitalMarketing,
    CompanyFace,
    RoleSupport,
    CrmPanel,
    // marketingUpgrade,
  },
  data () {
    return {
      pathUrl: '/solution',
      pathName: '',
    }
  },
  computed: {
    isCrmPage () {
      return ['/solution/crm', '/solution/crms'].includes(this.pathUrl)
    }
  },
  watch: {
    '$route.path' () {
      this.pathUrl = utils.changeRoutePath(this.$route.path)
      this.pathName = this.$route.name
    },
  },
  mounted () {
    this.pathUrl = utils.changeRoutePath(this.$route.path)
    this.pathName = this.$route.name
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
// 移动端布局
@import "@/views/solution/min-solution";
</style>
